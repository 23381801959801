import PurchaseBill from "@/services/PurchaseBillService";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

const purchaseBill = {
  namespaced: true,
  state: {
    purchaseBills: [],
    purchaseDetail: {}
  },
  getters: {
    getPurchaseBills(state) {
      return state.purchaseBills;
    },
    getPurchaseBillDetail(state) {
      return state.purchaseDetail;
    }
  },
  actions: {
    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        PurchaseBill.getAll()
          .then(response => {
            const payload = response.data?.bills;
            commit("GET_PURCHASE_BILL", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getById({ commit }, id) {
      return new Promise((resolve, reject) => {
        PurchaseBill.getDetail(id)
          .then(response => {
            const payload = response.data?.bills;
            commit("GET_PURCHASE_BILL_DETAIL", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          });
      });
    },
    getBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        PurchaseBill.getBySearch(params)
          .then(response => {
            if (response.data?.status === "0") {
              commit("SET_MESSAGE", response.data?.message, {
                root: true
              });
              reject();
            } else {
              commit("GET_PURCHASE_BILL", response.data?.bills);
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_PURCHASE_BILL: (state, payload) => {
      state.purchaseBills = payload;
    },
    GET_PURCHASE_BILL_DETAIL: (state, payload) => {
      state.purchaseDetail = payload;
    }
  }
};

export default purchaseBill;
