<template>
  <div id="ContactUs">
    <div class="header-1 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-16">
          <div class="info--text text-h5 text-md-h2 pa-5">
            Butuh
          </div>
          <div class="secondary--text text-h4 text-md-h1 pa-5">
            Bantuan?
          </div>
        </div>
      </v-container>
    </div>

    <!-- <div class="section-one secondary">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Apa yang bisa kami bantu ?
          </div>
          Comment this later
          <p class="text-center pa-5">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_owner.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Pemilik Toko</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_supplier.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Supplier</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_designer.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Designer</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Jasa Renovasi</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-two header-2 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="secondary--text text-h5 text-lg-h4 mt-5 mb-10">
            Masih ada yang mau ditanya?
          </div>
          Comment this later
          <p class="secondary--text mb-10">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <v-row>
            <v-col class="col-12 col-lg-6">
              <v-form ref="form" v-model="valid" autocomplete="off">
                <v-text-field
                  v-model="contact.userName"
                  label="Nama Lengkap"
                  solo
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="contact.userEmail"
                  label="Email"
                  solo
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="contact.userCompanyName"
                  label="Nama Perusahaan"
                  solo
                  clearable
                  required
                ></v-text-field>
                <v-textarea
                  v-model="contact.userMessage"
                  label="Pesan"
                  solo
                  no-resize
                  clearable
                  required
                ></v-textarea>
              </v-form>
            </v-col>
          </v-row>
          <v-row class="justify-center justify-md-start my-10 mx-0">
            <v-btn large color="success" class="px-16">Kirim</v-btn>
          </v-row>
        </div>
      </v-container>
    </div> -->
    <div class="section-seven header-2 secondary">
      <div class="black-background"></div>
      <v-container class="ext">
        <div>
          <div
            class="secondary--text text-center text-lg-left text-h5 text-lg-h4 mt-4"
          >
            Masih ada pertanyaan?
          </div>
          <v-row>
            <v-col>
              <v-row class="justify-center justify-md-start my-8 mx-0">
                <!-- <v-btn x-large color="info" to="/contactUs">Hubungi Kami</v-btn> -->
                <v-btn
                  href="https://wa.me/6281235123518"
                  target="_blank"
                  large
                  color="success"
                >
                  <v-icon left dark>
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contact: {},
      valid: true,
    };
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_six.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
}

.header-2 {
  background-image: url("../../assets/background_five.jpg");
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
