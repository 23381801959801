import api from "@/services/ApiService";

export default {
  getGraphic() {
    return api().post("/finance/graph");
  },
  getAll() {
    return api().post("/get_cash_in_outs");
  },
  getBySearch(params) {
    return api().post(`/get_cash_in_outs`, params);
  }
};
