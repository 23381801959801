import api from "@/services/ApiService";

export default {
  getAll() {
    return api().post("/mitra/purchase_bill");
  },
  getBySearch(params) {
    return api().post(`/mitra/purchase_bill`, params);
  },
  getDetail(id) {
    return api().post(`/mitra/purchase_bill/detail`, id);
  }
};
