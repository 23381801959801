import AccountingService from "@/services/AccountingService";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

const accounting = {
  namespaced: true,
  state: {
    accounting: {}
  },
  getters: {
    getAccounting(state) {
      return state.accounting;
    }
  },
  actions: {
    getAll({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        AccountingService.getAll(params)
          .then(response => {
            commit("GET_ACCOUNTING", response.data?.data);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_ACCOUNTING: (state, payload) => {
      state.accounting = payload;
    }
  }
};

export default accounting;
