import ReportService from "@/services/ReportService";
import CommonService from "@/services/CommonService";

const common = {
  namespaced: true,
  state: {
    listCustomer: [],
    listProduct: [],
    listDriver: [],
    listOperator: [],
    listDebtType: [],
    triggerSidebar: true
  },
  getters: {
    getCustomer(state) {
      return state.listCustomer;
    },
    getProduct(state) {
      return state.listProduct;
    },
    getDriver(state) {
      return state.listDriver;
    },
    getOperator(state) {
      return state.listOperator;
    },
    getDebtType(state) {
      return state.listDebtType;
    },
    getTriggerSidebar(state) {
      return state.triggerSidebar;
    }
  },
  actions: {
    getAllCustomer({ commit, rootState }) {
      rootState.loading = true;

      ReportService.getCustomer()
        .then(response => {
          const payload_customer = response.data.customers;
          commit("GET_CUSTOMER", payload_customer);

          rootState.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          rootState.loading = false;
        });
    },
    getAllProduct({ commit, rootState }) {
      rootState.loading = true;

      ReportService.getProduct()
        .then(response => {
          const payload_product = response.data.products;
          commit("GET_PRODUCT", payload_product);

          rootState.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          rootState.loading = false;
        });
    },
    getAllDriver({ commit, rootState }) {
      rootState.loading = true;

      ReportService.getDriver()
        .then(response => {
          const payload_driver = response.data.data;
          commit("GET_DRIVER", payload_driver);

          rootState.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          rootState.loading = false;
        });
    },
    getAllOperator({ commit, rootState }) {
      rootState.loading = true;

      CommonService.getOperator()
        .then(response => {
          const payload_operator = response.data.operators;
          commit("GET_OPERATOR", payload_operator);

          rootState.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          rootState.loading = false;
        });
    },
    getAllOperatorList({ commit, rootState }) {
      rootState.loading = true;

      CommonService.getOperatorList()
        .then(response => {
          const { data } = response.data;
          commit("GET_OPERATOR", data);

          rootState.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          rootState.loading = false;
        });
    },
    getAllDebtType({ commit, rootState }) {
      rootState.loading = true;

      CommonService.getDebtType()
        .then(response => {
          const payload_debt_Type = response.data.data;
          commit("GET_DEBT_TYPE", payload_debt_Type);

          rootState.loading = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          rootState.loading = false;
        });
    },
    setTriggerSidebar({ commit }, triggerSidebar) {
      try {
        commit("SET_TRIGGER_SIDEBAR", triggerSidebar);
      } catch (error) {
        console.log(error);
      }
    }
  },
  mutations: {
    GET_CUSTOMER: (state, payload) => {
      state.listCustomer = payload;
    },
    GET_PRODUCT: (state, payload) => {
      state.listProduct = payload;
    },
    GET_DRIVER: (state, payload) => {
      state.listDriver = payload;
    },
    GET_OPERATOR: (state, payload) => {
      state.listOperator = payload;
    },
    GET_DEBT_TYPE: (state, payload) => {
      state.listDebtType = payload;
    },
    SET_TRIGGER_SIDEBAR: (state, payload) => {
      state.triggerSidebar = payload;
    }
  }
};

export default common;
