import Vue from "vue";
import Vuetify from "vuetify/lib";
import light from "./theme";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

const opts = {
  theme: {
    dark: false,
    themes: {
      light: light,
      dark: {
        primary: "#002B73",
        secondary: "#FFFFFF",
        accent: "#002B73",
        error: "#BF1515",
        info: "#335EA6",
        success: "#05790A",
        warning: "#F1B203",
        disabled: "#ABB1BB"
      }
    }
  }
};

export default new Vuetify(opts);
