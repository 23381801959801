export default {
  primary: "#002B73", //#2F4858
  secondary: "#FFFFFF",
  accent: "#002B73",
  error: "#BF1515",
  info: "#335EA6",
  success: "#05790A",
  warning: "#FEC554",
  disabled: "#ABB1BB"
};
