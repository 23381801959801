import Vue from "vue";
import VueRouter from "vue-router";
import {
  publicRoute,
  protectedMitraRoute,
  protectedSupplierRoute,
  protectedAdminRoute
} from "./config";
import store from "../store/index.js";
// import NProgress from "nprogress";
// import "../../node_modules/nprogress/nprogress.css";

const routes = publicRoute
  .concat(protectedMitraRoute)
  .concat(protectedSupplierRoute)
  .concat(protectedAdminRoute);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.isLoggedIn) {
      next();
    } else {
      next("/");
    }
    return;
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!store.getters.isLoggedIn) {
      next();
      return;
    }

    if (store.getters.getUser.user.user_role_id === 3) {
      next("/manageSupplier");
    } else if (store.getters.getUser.user.user_role_id === 4) {
      next("/dashboardMitra");
    } else if (store.getters.getUser.user.user_role_id === 5) {
      next("/productSupplier");
    }

    return;
  } else {
    next();
  }

  // if (to.matched.some(record => record.meta.auth)) {
  //   if (store.getters.isLoggedIn) {
  //     next();
  //     return;
  //   }
  //   next("/");
  // }

  // if (to.matched.some(record => record.meta.guest)) {
  //   if (!store.getters.isLoggedIn) {
  //     next();
  //     return;
  //   }
  //   next("/dashboardMitra");
  // }

  // next();
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    // NProgress.start();
  }
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  // NProgress.done();
});

export default router;
