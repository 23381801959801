import LandingScreen from "@/views/Landing/LandingScreen.vue";
import ProfileScreen from "@/views/Landing/ProfileScreen.vue";
import RegisterMitraScreen from "@/views/Landing/RegisterMitraScreen.vue";
import ContactUsScreen from "@/views/Landing/ContactUsScreen.vue";
import CareerScreen from "@/views/Landing/CareerScreen.vue";
import HelpFAQScreen from "@/views/Landing/HelpFAQScreen.vue";
import RegisterSupplierScreen from "@/views/Landing/RegisterSupplierScreen.vue";
import RegisterContractorScreen from "@/views/Landing/RegisterContractorScreen.vue";
import RegisterAssistantScreen from "@/views/Landing/RegisterAssistantScreen.vue";

export const publicRoute = [
  {
    path: "*",
    redirect: "/"
  },
  {
    path: "/",
    component: LandingScreen,
    name: "Landing",
    meta: {
      guest: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: ProfileScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/registerMitra",
    name: "RegisterMitra",
    component: RegisterMitraScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/contactUs",
    name: "ContactUs",
    component: ContactUsScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/career",
    name: "Carrer",
    component: CareerScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/helpFAQ",
    name: "HelpFAQ",
    component: HelpFAQScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/registerSupplier",
    name: "RegisterSupplier",
    component: RegisterSupplierScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/registerContractor",
    name: "RegisterContractor",
    component: RegisterContractorScreen,
    meta: {
      guest: true
    }
  },
  {
    path: "/registerAssistant",
    name: "RegisterAssistant",
    component: RegisterAssistantScreen,
    meta: {
      guest: true
    }
  }
];

export const protectedMitraRoute = [
  {
    path: "/dashboardMitra",
    name: "DashboardMitra",
    meta: {
      title: "DashboardMitra",
      group: "apps",
      icon: "dashboard",
      auth: true
    },
    component: () => import("@/views/Mitra/Dashboard.vue")
  },
  {
    path: "/sales",
    name: "Sales",
    meta: { title: "sales", group: "apps", icon: "sales", auth: true },
    component: () => import("@/views/Mitra/Sales.vue")
  },
  {
    path: "/purchaseBill",
    name: "PurchaseBill",
    meta: {
      title: "purchaseBill",
      group: "apps",
      icon: "purchaseBill",
      auth: true
    },
    component: () => import("@/views/Mitra/PurchaseBill.vue")
  },
  {
    path: "/finance",
    name: "Finance",
    meta: { title: "finance", group: "apps", icon: "finance", auth: true },
    component: () => import("@/views/Mitra/Finance.vue")
  },
  {
    path: "/stock",
    name: "Stock",
    meta: { title: "Stock", group: "apps", icon: "stock", auth: true },
    component: () => import("@/views/Mitra/Stock.vue")
  },
  {
    path: "/accounting",
    name: "Accounting",
    meta: { title: "Akunting", group: "apps", icon: "accounting", auth: true },
    component: () => import("@/views/Mitra/Accounting.vue")
  },
  {
    path: "/report",
    name: "Report",
    meta: { title: "Report", group: "apps", icon: "report", auth: true },
    component: () => import("@/views/Mitra/Report.vue")
  },
  {
    path: "/uploadStoreProduct",
    name: "UploadStoreProduct",
    meta: {
      title: "UploadStoreProduct",
      group: "apps",
      icon: "uploadStoreProduct",
      auth: true
    },
    component: () => import("@/views/Mitra/UploadStoreProductScreen.vue")
  },
  {
    path: "/manageAdmin",
    name: "ManageAdmin",
    meta: {
      title: "manageAdmin",
      group: "apps",
      icon: "manageAdmin",
      auth: true
    },
    component: () => import("@/views/Mitra/ManageAdminScreen.vue")
  }
];

export const protectedSupplierRoute = [
  {
    path: "/productSupplier",
    name: "ProductSupplier",
    meta: {
      title: "ProductSupplier",
      group: "apps",
      icon: "productSupplier",
      auth: true
    },
    component: () => import("@/views/Supplier/ProductScreen.vue")
  },
  {
    path: "/purchaseOrderSupplier",
    name: "PurchaseOrderSupplier",
    meta: {
      title: "PurchaseOrderSupplier",
      group: "apps",
      icon: "purchaseOrderSupplier",
      auth: true
    },
    component: () => import("@/views/Supplier/PurchaseOrderScreen.vue")
  },
  {
    path: "/invoiceSupplier",
    name: "InvoiceSupplier",
    meta: {
      title: "InvoiceSupplier",
      group: "apps",
      icon: "invoiceSupplier",
      auth: true
    },
    component: () => import("@/views/Supplier/InvoiceScreen.vue")
  },
  {
    path: "/airwayBillSupplier",
    name: "AirwayBillSupplier",
    meta: {
      title: "AirwayBillSupplier",
      group: "apps",
      icon: "airwayBillSupplier",
      auth: true
    },
    component: () => import("@/views/Supplier/AirwayBillScreen.vue")
  },
  {
    path: "/airwayBillAddSupplier",
    name: "AirwayBillAddSupplier",
    meta: {
      title: "AirwayBillAddSupplier",
      group: "apps",
      icon: "airwayBillAddSupplier",
      auth: true
    },
    component: () => import("@/views/Supplier/AirwayBillAddScreen.vue")
  },
  {
    path: "/complainSupplier",
    name: "ComplainSupplier",
    meta: {
      title: "ComplainSupplier",
      group: "apps",
      icon: "complainSupplier",
      auth: true
    },
    component: () => import("@/views/Supplier/ComplainScreen.vue")
  }
];

export const protectedAdminRoute = [
  {
    path: "/uploadProductMaster",
    name: "UploadProductMaster",
    meta: {
      title: "UploadProductMaster",
      group: "apps",
      icon: "uploadProductMaster",
      auth: true
    },
    component: () => import("@/views/Admin/UploadProductMasterScreen.vue")
  },
  {
    path: "/manageSupplier",
    name: "ManageSupplier",
    meta: {
      title: "ManageSupplier",
      group: "apps",
      icon: "manageSupplier",
      auth: true
    },
    component: () => import("@/views/Admin/ManageSupplierScreen.vue")
  },
  {
    path: "/paymentAdmin",
    name: "PaymentAdmin",
    meta: {
      title: "PaymentAdmin",
      group: "apps",
      icon: "paymentAdmin",
      auth: true
    },
    component: () => import("@/views/Admin/PaymentScreen.vue")
  },
  {
    path: "/mitraRegistration",
    name: "MitraRegistration",
    meta: {
      title: "MitraRegistration",
      group: "apps",
      icon: "mitraRegistration",
      auth: true
    },
    component: () => import("@/views/Admin/MitraRegistrationScreen.vue")
  },
  {
    path: "/supplierRegistration",
    name: "SupplierRegistration",
    meta: {
      title: "SupplierRegistration",
      group: "apps",
      icon: "supplierRegistration",
      auth: true
    },
    component: () => import("@/views/Admin/SupplierRegistrationScreen.vue")
  },
  {
    path: "/assistantRegistration",
    name: "AssistantRegistration",
    meta: {
      title: "AssistantRegistration",
      group: "apps",
      icon: "assistantRegistration",
      auth: true
    },
    component: () => import("@/views/Admin/AssistantRegistrationScreen.vue")
  },
  {
    path: "/contractorRegistration",
    name: "ContractorRegistration",
    meta: {
      title: "ContractorRegistration",
      group: "apps",
      icon: "contractorRegistration",
      auth: true
    },
    component: () => import("@/views/Admin/ContractorRegistrationScreen.vue")
  }
];
