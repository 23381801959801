<template>
  <div class="primary">
    <v-container pa-8>
      <v-row class="justify-center">
        <v-col class="col-12 col-md-4 px-5">
          <v-row class="text-center justify-center justify-md-start">
            <v-img
              class="mb-5"
              alt="Tepat Waktu"
              src="@/assets/lajual_logo.png"
              max-width="50"
            />
          </v-row>
          <div
            class="secondary--text text-h5 text-lg-h4 text-center text-md-left"
          >
            Lajual
          </div>
          <div
            class="secondary--text text-subtitle-1 text-h6 text-center text-md-left mt-2"
          >
            PT. Lajual Membangun Indonesia
          </div>
          <!-- <div
            class="secondary--text text-body-2 text-lg-subtitle-2 mt-2 text-center text-md-left"
          >
            Bulak Kapal Permai, Jl. Baruna 6, Blok AH3 No 72 Tambun Selatan, Kab
            Bekasi, Jawa Barat, 17510
          </div> -->
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-3 px-5">
          <v-list dark class="primary">
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in menus"
                :key="i"
                :to="item.menu_route"
                link
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="text-subtitle-2 text-lg-subtitle-2 text-center text-sm-left"
                    >{{ item.menu_name }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-4 px-5">
          <div class="secondary--text text-h6 mt-5 text-center text-sm-left">
            Unduh
          </div>
          <a
            href="https://play.google.com/"
            rel="noopener"
            target="_blank"
            class="d-flex justify-center justify-sm-start"
          >
            <v-img
              alt="Google Play"
              src="@/assets/google_play.png"
              max-width="150"
            />
          </a>
          <div class="secondary--text text-h6 mt-10 text-center text-sm-left">
            Follow Kami
          </div>
          <v-row class="mt-3 text-center text-sm-left">
            <v-col class="col-12">
              <!-- <router-link to="/">
                <p class="secondary--text font-weight-bold">
                  <v-icon class="mx-2 my-1" large dark>mdi-facebook</v-icon>
                  lajual
                </p>
              </router-link> -->
              <router-link to="/">
                <p class="secondary--text font-weight-bold">
                  <v-icon class="mx-2 my-1" large dark>mdi-instagram</v-icon>
                  lajual
                </p>
              </router-link>
              <!-- <router-link to="/">
                <p class="secondary--text font-weight-bold">
                  <v-icon class="mx-2 my-1" large dark>mdi-twitter</v-icon>
                  lajual
                </p>
              </router-link> -->
            </v-col>
            <!-- <v-col class="col-6">
              <router-link to="/">
                <p class="secondary--text font-weight-bold">
                  <v-icon class="mx-2 my-1" large dark>mdi-youtube</v-icon>
                  lajual
                </p>
              </router-link>
              <router-link to="/">
                <p class="secondary--text font-weight-bold">
                  <v-icon class="mx-2 my-1" large dark>mdi-linkedin</v-icon>
                  lajual
                </p>
              </router-link>
            </v-col> -->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        {
          menu_name: "Beranda",
          menu_route: "/",
        },
        {
          menu_name: "Daftar",
          menu_route: "/registerMitra",
        },
        // {
        //   menu_name: "Blog",
        //   menu_route: "/blog",
        // },
        // {
        //   menu_name: "Karir",
        //   menu_route: "/career",
        // },
        // {
        //   menu_name: "Bantuan & FAQ",
        //   menu_route: "/helpFAQ",
        // },
        {
          menu_name: "Syarat & Ketentuan",
          menu_route: "/termCondition",
        },
        {
          menu_name: "Kebijakan Privasi",
          menu_route: "/privacyPolicy",
        },
      ],
    };
  },
};
</script>
