import Vue from "vue";
import vTostini from "v-tostini";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import VueExpandableImage from "vue-expandable-image";
import VueTheMask from "vue-the-mask";
import VuetifyMoney from "vuetify-money";

import "./app.scss";
import "./plugins/vuetify-money.js";
import "@mdi/font/css/materialdesignicons.css";

import { setHeaderToken } from "./utils/auth";

Vue.config.productionTip = false;
Vue.use(vTostini);
Vue.use(VueExpandableImage);
Vue.use(VueTheMask);
Vue.use(VuetifyMoney);

const token = localStorage.getItem("token");
if (token) {
  setHeaderToken(token);
}

store
  .dispatch("get_user", token)
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      vTostini,
      i18n,
      render: h => h(App)
    }).$mount("#app");
  })
  .catch(error => {
    console.error(error);
  });
