import setter from "../../utils/auth";
import router from "../../router";
import api from "@/services/ApiService";
import VueRouter from "vue-router";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export default {
  state: {
    user: null,
    isLoggedIn: false,
    user_role: "",
    user_notification: null
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    getUser(state) {
      return state.user;
    },
    role(state) {
      return state.user_role;
    },
    getNotification(state) {
      return state.user_notification;
    }
  },
  actions: {
    login({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        api()
          .post("/user/login", {
            email: data.email,
            password: data.password
          })
          .then(response => {
            const token = response.data.access_token;
            localStorage.setItem("token", token);
            setter.setHeaderToken(token);
            dispatch("get_user");
            resolve(response);
          })
          .catch(err => {
            commit("SET_USER");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    async get_user({ commit }) {
      if (!localStorage.getItem("token")) {
        return;
      }
      try {
        let response = await api().post("/user/data");
        if (response.data.message != "token tidak sesuai") {
          commit("SET_USER", response.data);
          commit("SET_ROLE", response.data.user.user_role_id);
          if (response.data.user.user_role_id === 3) {
            // let notification = await api().post("/admin/notification");
            // commit("SET_NOTIFICATION", notification.data.result);
            router.push({ name: "ManageSupplier" }).catch(failure => {
              if (
                isNavigationFailure(failure, NavigationFailureType.redirected)
              ) {
                // show a small notification to the user
                alert("Login in order to access the admin panel");
              }
            });
          } else if (response.data.user.user_role_id === 4) {
            router.push({ name: "DashboardMitra" }).catch(failure => {
              if (
                isNavigationFailure(failure, NavigationFailureType.redirected)
              ) {
                alert("Login in order to access the admin panel");
              }
            });
          } else if (response.data.user.user_role_id === 5) {
            // let notification = await api().post("/supplier/notification");
            // commit("SET_NOTIFICATION", notification.data.result);
            router.push({ name: "ProductSupplier" }).catch(failure => {
              if (
                isNavigationFailure(failure, NavigationFailureType.redirected)
              ) {
                alert("Login in order to access the admin panel");
              }
            });
          } else {
            router.push("/").catch();
          }
        } else {
          commit("RESET_USER");
          setter.removeHeaderToken();
          localStorage.removeItem("token");
          this.isLoggedIn = false;
        }
      } catch (error) {
        commit("RESET_USER");
        setter.removeHeaderToken();
        localStorage.removeItem("token");
        return error;
      }
    },
    async get_notification({ commit }, user_role) {
      try {
        if (user_role === "3") {
          let notification = await api().post("/admin/notification");
          commit("SET_NOTIFICATION", notification.data.result);
          router.push("/paymentAdmin").catch();
        } else {
          let notification = await api().post("/supplier/notification");
          commit("SET_NOTIFICATION", notification.data.result);
          router.push("/purchaseOrderSupplier").catch();
        }
      } catch (error) {
        console.log(error);
      }
    },
    removeSession({ commit }) {
      return new Promise(resolve => {
        commit("RESET_USER");
        localStorage.removeItem("token");
        setter.removeHeaderToken();
        resolve();
      });
    }
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data;
      state.isLoggedIn = true;
    },
    RESET_USER(state) {
      state.user = null;
      state.isLoggedIn = false;
    },
    SET_ROLE(state, role) {
      state.user_role = role;
    },
    SET_NOTIFICATION(state, notif) {
      state.user_notification = notif;
    }
  }
};
