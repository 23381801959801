import MitraDashboardService from "@/services/MitraDashboardService";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

const mitraDashboard = {
  namespaced: true,
  state: {
    monthlyProgress: {},
    topTransaction: [],
    annualTransaction: []
  },
  getters: {
    getMonthlyProgress(state) {
      return state.monthlyProgress;
    },
    getTopTransaction(state) {
      return state.topTransaction;
    },
    getAnnualTransaction(state) {
      return state.annualTransaction;
    }
  },
  actions: {
    getMonthlyProgress({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        MitraDashboardService.getMonthlyProgress()
          .then(response => {
            commit("GET_MONTHLY_PROGRESS", response.data?.data);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getTopTransaction({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        MitraDashboardService.getTopTransaction()
          .then(response => {
            let data = [];

            for (let i = 0; i < response.data?.data?.length; i++) {
              data.push({
                Item: response.data.data[i].name,
                Jumlah: response.data.data[i].value
              });
            }

            commit("GET_TOP_TRANSACTION", data);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getAnnualTransaction({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        MitraDashboardService.getAnnualTransaction()
          .then(response => {
            let data = [];

            data.push({
              Month: "Kuartal 1",
              Pemasukan: response.data.data.q1.pemasukan,
              Pengeluaran: response.data.data.q1.pengeluaran
            });

            data.push({
              Month: "Kuartal 2",
              Pemasukan: response.data.data.q2.pemasukan,
              Pengeluaran: response.data.data.q2.pengeluaran
            });

            data.push({
              Month: "Kuartal 3",
              Pemasukan: response.data.data.q3.pemasukan,
              Pengeluaran: response.data.data.q3.pengeluaran
            });

            data.push({
              Month: "Kuartal 4",
              Pemasukan: response.data.data.q4.pemasukan,
              Pengeluaran: response.data.data.q4.pengeluaran
            });

            commit("GET_ANNUAL_TRANSACTION", data);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_MONTHLY_PROGRESS: (state, payload) => {
      state.monthlyProgress = payload;
    },
    GET_TOP_TRANSACTION: (state, payload) => {
      state.topTransaction = payload;
    },
    GET_ANNUAL_TRANSACTION: (state, payload) => {
      state.annualTransaction = payload;
    }
  }
};

export default mitraDashboard;
