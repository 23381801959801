import { render, staticRenderFns } from "./RegisterSupplierScreen.vue?vue&type=template&id=37dc749c&scoped=true"
import script from "./RegisterSupplierScreen.vue?vue&type=script&lang=js"
export * from "./RegisterSupplierScreen.vue?vue&type=script&lang=js"
import style0 from "./RegisterSupplierScreen.vue?vue&type=style&index=0&id=37dc749c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37dc749c",
  null
  
)

export default component.exports