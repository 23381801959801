import api from "@/services/ApiService";

export default {
  logout() {
    return api().post("/user/logout");
  },
  getSupplier() {
    return api().post("/supplier");
  },
  getSupplierWithNotification() {
    return api().post("/admin/get_supplier");
  },
  getMitra() {
    return api().post("/admin/get_mitra");
  },
  getStatus() {
    return api().post("/status");
  },
  submitMitra(params) {
    return api().post(`/user/add_user_mitra`, params);
  },
  submitAssistant(params) {
    return api().post(`/user/request-user-assistant-contractor`, params);
  },
  submitSupplier(payload) {
    return api().post(`/user/request-user-supplier`, payload, {
      headers: {
        "Content-Type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random()
            .toString()
            .substr(2)
      }
    });
  },
  submitContractor(payload) {
    return api().post(`/user/request-user-contractor`, payload, {
      headers: {
        "Content-Type":
          "multipart/form-data; charset=utf-8; boundary=" +
          Math.random()
            .toString()
            .substr(2)
      }
    });
  },
  getAllBank() {
    return api().post("/bank");
  },
  getOperator() {
    return api().post("/mitra/operator");
  },
  getOperatorList() {
    return api().get("/mitra/operators?all=true");
  },
  getDebtType() {
    return api().get("/transaction/debt_type");
  },
  changePassword(payload) {
    return api().post(`/reset_password`, payload);
  }
};
