<template>
  <v-app>
    <header-layout />
    <sidebar-layout />

    <v-main>
      <router-view></router-view>
      <footer-layout v-if="!isLoggedIn"></footer-layout>
    </v-main>

    <v-footer
      class="d-flex justify-center justify-lg-space-between"
      absolute
      inset
      app
    >
      <div class="text-body-2 text-lg-body-1 my-2">
        &copy; 2020 Lajual. All Rights Reserved
      </div>
      <div class="text-center my-2">
        Icons made by
        <a href="https://www.freepik.com" title="Freepik">Freepik</a> from
        <a href="https://www.flaticon.com/" title="Flaticon"
          >www.flaticon.com</a
        >
      </div>
      <LocaleSwitcher />
    </v-footer>

    <confirm-modal ref="confirmModal"></confirm-modal>
    <loader-modal ref="loaderModal"></loader-modal>
    <change-password-modal v-model="showChangePasswordModal" />

    <tostini-plate />

    <!-- <v-btn
      v-show="fab"
      v-scroll="onScroll"
      fab
      fixed
      bottom
      right
      color="info"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn> -->
  </v-app>
</template>

<script>
import HeaderLayout from "@/components/Layout/HeaderLayout";
import SidebarLayout from "@/components/Layout/SidebarLayout";
import FooterLayout from "@/components/Layout/FooterLayout";
import ConfirmModal from "@/components/Modal/ConfirmModal";
import LoaderModal from "@/components/Modal/LoaderModal";
import ChangePasswordModal from "@/components/Modal/ChangePasswordModal";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import store from "@/store/index.js";

export default {
  components: {
    HeaderLayout,
    SidebarLayout,
    FooterLayout,
    LoaderModal,
    ConfirmModal,
    ChangePasswordModal,
    LocaleSwitcher,
  },

  data() {
    return {
      fab: false,
      showChangePasswordModal: false,
      services: [
        { title: "Daftar Menjadi Mitra", route: "/registerMitra" },
        // { title: "Asisten Renovasi Rumahmu", route: "/registerAssistant" },
        { title: "Daftar Menjadi Supplier", route: "/registerSupplier" },
        // {
        //   title: "Bergabung Menjadi Kontraktor dan Designer",
        //   route: "/registerContractor",
        // },
      ],
      // middleware: 'auth'
    };
  },

  mounted() {
    this.$root.$confirmModal = this.$refs.confirmModal.open;
    this.$root.$loaderModal = this.$refs.loaderModal;
    this.$root.$on("updateNotifSupplier", () => {
      this.$store.dispatch("get_notification", 5);
    });
    this.$root.$on("updateNotifAdmin", () => {
      this.$store.dispatch("get_notification", 3);
    });
  },

  computed: {
    isLoggedIn() {
      return store.getters.isLoggedIn;
    },
  },

  beforeUpdate() {
    if (
      store.getters.getUser?.user?.user_role_id === 5 &&
      store.getters.getUser?.user?.first_login === 1
    ) {
      this.showChangePasswordModal = true;
    }
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") {
        return;
      }
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>
