import api from "@/services/ApiService";

export default {
  getAll() {
    return api().post("/product/search/userproduct");
  },
  getBySearch(params) {
    return api().post(`/product/search/userproduct`, params);
  }
};
