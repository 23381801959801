<template>
  <div id="RegisterMitra">
    <!-- <div class="header-1 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-16">
          <div class="secondary--text text-h5 text-md-h2 pa-5">
            Bergabung Menjadi
          </div>
          <div class="secondary--text text-h4 text-md-h1 pa-5">
            Mitra
          </div>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-one">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex">
              <v-img
                alt="Contact Us"
                src="@/assets/icon_owner.jpg"
                class="mx-auto"
                max-height="500"
                max-width="500"
              />
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title>
                  <div class="text-center text-lg-left text-h6 text-lg-h5 mt-5">
                    Ekosistem Digital Terlengkap Untuk Memulai Bisnis
                  </div>
                </v-card-title>
                <v-card-text>
                  <p class="text-center text-lg-left">
                    Sistem Manajemen Toko (POS) Mengembangkan Bisnis Anda
                    Berjalan Tanpa Kehadiran Anda
                  </p>
                  <v-row class="justify-center text-center mb-5">
                    <v-btn outlined color="info" class="ma-2">Nyaman</v-btn>
                    <v-btn outlined color="info" class="ma-2">Mudah</v-btn>
                    <v-btn outlined color="info" class="ma-2">Terpercaya</v-btn>
                  </v-row>
                  <div>
                    <v-chip outlined class="ma-2 px-5">Solusi Penjualan</v-chip>
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Solusi Pencatatan
                    </v-chip>
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Solusi Pemesanan Barang
                    </v-chip>
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Solusi Pengiriman Barang
                    </v-chip>
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Solusi Pengembangan Usaha
                    </v-chip>
                  </div>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn
                      large
                      color="info"
                      @click="scrollMeTo('registration')"
                      >Daftar Sekarang</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <v-divider /> -->

    <!-- <div class="section-two">
      <v-container class="ext" py-16>
        <div>
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-16">
            Kemudahan Mengelola Toko Material
          </div>
          <v-row class="py-10 d-flex justify-space-between">
            <v-col class="col-7 d-none d-md-inline-flex"
              ><v-img
                alt="Sales Of Point"
                src="@/assets/screenshot_pos.jpg"
                class="mx-auto"
                aspect-ratio="1"
            /></v-col>
            <v-col class="col-12 col-md-4">
              <v-card class="mx-auto px-5 info">
                <v-card-title class="justify-center justify-md-start">
                  <div
                    class="secondary--text text-center text-h6 text-lg-h5 mt-5"
                  >
                    Gak ada lagi repot merapihkan bon yang berantakan
                  </div>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p class="secondary--text text-center text-md-left">
                    Sistem kasir membantu anda untuk melakukan penjualan dan
                    pencatatan harga dengan sentuhan jari
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="py-10 d-flex justify-space-between">
            <v-col class="col-12 col-md-5">
              <v-card class="mx-auto px-5 warning">
                <v-card-title class="justify-center justify-md-start">
                  <div class="text-center text-h6 text-lg-h5 mt-5">
                    Gak usah cape bikin laporan
                  </div>
                </v-card-title>
                <v-divider />
                <div class="mt-5">
                  <v-chip outlined class="ma-2 px-5">Laporan Penjualan</v-chip>
                </div>
                <div>
                  <v-chip outlined class="ma-2 px-5"
                    >Laporan Stok Barang</v-chip
                  >
                </div>
                <div>
                  <v-chip outlined class="ma-2 px-5"
                    >Laporan Pendapatan
                  </v-chip>
                </div>
                <div>
                  <v-chip outlined class="ma-2 px-5"
                    >Laporan Pengeluaran
                  </v-chip>
                </div>
                <div>
                  <v-chip outlined class="ma-2 px-5"
                    >Rangkuman pemesanan barang
                  </v-chip>
                </div>
                <v-card-text>
                  <p class="black--text text-center text-md-left">
                    Tersedia Instan
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="col-6 d-none d-md-inline-flex"
              ><v-img
                alt="Dashboard"
                src="@/assets/screenshot_dashboard.png"
                class="mx-auto"
                aspect-ratio="2"
            /></v-col>
          </v-row>
          <v-row class="py-10 d-flex justify-space-between">
            <v-col class="col-7 d-none d-md-inline-flex"
              ><v-img
                alt="Purchase Order"
                src="@/assets/screenshot_purchase.jpg"
                class="mx-auto"
                aspect-ratio="1"
            /></v-col>
            <v-col class="col-12 col-md-4">
              <v-card class="mx-auto px-5 info ">
                <v-card-title class="justify-center justify-md-start">
                  <div
                    class="secondary--text text-center text-h6 text-lg-h5 mt-5"
                  >
                    Gak usa bingung cari supplier terbaik
                  </div>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p class="secondary--text text-center text-md-left">
                    Harga kompetitif
                  </p>
                  <p class="secondary--text text-center text-md-left">
                    Barang Terjamin
                  </p>
                  <p class="secondary--text text-center text-md-left">
                    Bisa Order Kapan Saja
                  </p>
                  <p class="secondary--text text-center text-md-left">
                    Supplier Terpercaya
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="py-10 d-flex justify-space-between">
            <v-col class="col-12 col-md-4">
              <v-card class="mx-auto px-5 warning">
                <v-card-title class="justify-center justify-md-start">
                  <div class="text-center text-h6 text-lg-h5 mt-5">
                    Gak usa ribet buat surat jalan
                  </div>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p class="black--text text-center text-md-left">
                    Pencatatan Pengiriman dan Pembuatan surat jalan terhubung
                    dengan system penjualan
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="col-7 d-none d-md-inline-flex"
              ><v-img
                alt="Contact Us"
                src="@/assets/screenshot_airway.png"
                class="mx-auto"
                aspect-ratio="2"
            /></v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-three info d-none d-md-flex">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row justify="center">
            <div class="secondary--text text-h5 text-lg-h4 mb-10">
              Cerita Dari Mereka
            </div>
          </v-row>
          <v-row>
            <v-carousel cycle hide-delimiters height="500" class="mx-16">
              <v-carousel-item v-for="(data, i) in dataClients" :key="i">
                <v-card class="mx-16 mt-10 secondary">
                  <div class="d-flex justify-space-between">
                    <div class="pa-5">
                      <v-img
                        alt
                        width="100"
                        height="100"
                        class="ml-2"
                        src="@/assets/logo_example.jpg"
                      ></v-img>
                      <v-card-title>
                        <div class="black--text text-h6">
                          {{ data.client_name }}
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="black--text text-caption">
                          Pemilik dari {{ data.client }}
                        </div>
                        <p class="black--text font-weight-bold mt-8">
                          {{ `" ${data.message} "` }}
                        </p>
                      </v-card-text>
                    </div>
                    <div class="d-none d-md-inline-flex">
                      <v-img
                        lazy-src="@/assets/image_person_one.jpg"
                        alt="Client Picture"
                        src="@/assets/image_person_one.jpg"
                        height="450"
                        width="450"
                      />
                    </div>
                  </div>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-one">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title>
                  <div class="text-center text-lg-left text-h6 text-lg-h5 mt-5">
                    Ayo tingkatkan penjualanmu dengan Asisten Renovasi Lajual!
                  </div>
                </v-card-title>
                <v-card-text>
                  <p class="text-center text-lg-left">
                    Bingung rekomendasi tukang, kontraktor, dan designer untuk
                    renovasi rumah pelanggan?
                  </p>
                  <p class="text-center text-lg-left">
                    Kami bantu mmenyediakan profesional berpengalaman yang
                    terjamin dan bergaransi!
                  </p>
                  <p class="text-center text-lg-left">
                    Yang pastinya akan meningkatkan penjualan toko kamu.
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="col-6 d-none d-md-inline-flex">
              <v-img
                alt="Contact Us"
                src="@/assets/icon_owner.jpg"
                class="mx-auto"
                max-height="300"
                max-width="300"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <div class="section-five" ref="registration">
      <v-container class="ext" py-16>
        <div class="mx-5">
          <v-row class="justify-center">
            <div class="text-center text-h5 text-lg-h4 mb-16">
              Pendaftaran Menjadi Mitra Lajual
            </div>
          </v-row>
          <!-- <v-row>
            <div class="text-h6">
              Syarat Bergabung
            </div>
          </v-row>
          <ul class="mb-16">
            <li>Berdomisili di wilayah Jakarta, dan sekitarnya.</li>
            <li>Memiliki Usaha Toko Bangunan</li>
            <li>Memiliki Bangunan Permanen</li>
          </ul> -->
          <p class="text-center text-md-left">
            Dengan melakukan pengisian formulir dibawah ini, setelah itu tim
            kami akan memvalidasi data anda, dan anda hanya perlu menunggu
            panggilan telepon dari tim kami, estimasi waktu yang diberikan
            adalah 3 hari kerja, terima kasih.
          </p>
          <v-form ref="form" v-model="valid" autocomplete="off">
            <v-row>
              <v-col class="col-12 col-md-7">
                <v-text-field
                  v-model="mitra.storeName"
                  :counter="30"
                  :rules="storeNameRules"
                  label="Nama Toko*"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.ktpNumber"
                  :counter="16"
                  :rules="ktpNumberRules"
                  label="Nomor KTP*"
                  clearable
                  required
                ></v-text-field>

                <!-- <v-text-field
                  v-model="npwpNumber"
                  :counter="15"
                  :rules="npwpNumberRules"
                  label="Nomor NPWP*"
                  clearable
                  required
                ></v-text-field> -->

                <!-- <v-text-field
                v-model="npwpNumber"
                :counter="15"
                :rules="npwpNumberRules"
                label="Nomor NPWP*"
                v-mask="['##.###.###.#-###.###']"
                clearable
                required
              ></v-text-field> -->

                <v-text-field
                  v-model="mitra.ownerName"
                  :counter="30"
                  :rules="ownerNameRules"
                  label="Nama Pemilik Toko*"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.email"
                  :rules="emailRules"
                  label="E-mail*"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.phoneNumber"
                  :counter="12"
                  :rules="phoneNumberRules"
                  label="Nomor Telepon / Hp*"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.accountNumber"
                  :rules="accountNumberRules"
                  label="Nomor Rekening*"
                  clearable
                  required
                ></v-text-field>

                <v-autocomplete
                  v-model="mitra.bankCode"
                  :items="bankCodeList"
                  :rules="bankCodeRules"
                  item-value="bank_id"
                  label="Masukkan Kode Bank"
                >
                  <template slot="selection" slot-scope="data">
                    ({{ data.item.bank_code }})
                    {{ data.item.bank_name }}
                  </template>
                  <template slot="item" slot-scope="data">
                    ({{ data.item.bank_code }})
                    {{ data.item.bank_name }}
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-model="mitra.accountName"
                  :rules="accountNameRules"
                  label="Nama Pemilik Rekening*"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.address"
                  :counter="150"
                  :rules="addressRules"
                  label="Alamat Toko*"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.postalCode"
                  :counter="5"
                  :rules="postalCodeRules"
                  label="Kode Pos*"
                  v-mask="['#####']"
                  clearable
                  required
                ></v-text-field>

                <v-text-field
                  v-model="mitra.password"
                  :append-icon="passwordEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="passwordEye ? 'text' : 'password'"
                  label="Password*"
                  counter
                  required
                  @click:append="passwordEye = !passwordEye"
                ></v-text-field>

                <v-text-field
                  v-model="mitra.confirmPassword"
                  :append-icon="passwordConfirmEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="confirmPasswordRules"
                  :type="passwordConfirmEye ? 'text' : 'password'"
                  label="Konfirmasi Password*"
                  counter
                  required
                  @click:append="passwordConfirmEye = !passwordConfirmEye"
                ></v-text-field>

                <v-checkbox
                  v-model="isAggrement"
                  :rules="[(v) => !!v || 'Wajib Dicentang!']"
                  label="Dengan ini anda menyetujui segala persyaratan yang ada. Pastikan data yang anda masukkan benar."
                  required
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="col-7">
              <v-btn
                :disabled="loading"
                :loading="loading"
                color="success"
                @click="submitData"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-seven header-2 secondary">
      <div class="black-background"></div>
      <v-container class="ext">
        <div>
          <div
            class="secondary--text text-center text-lg-left text-h5 text-lg-h4 mt-4"
          >
            Masih ada pertanyaan?
          </div>
          <v-row>
            <v-col>
              <v-row class="justify-center justify-md-start my-8 mx-0">
                <!-- <v-btn x-large color="info" to="/contactUs">Hubungi Kami</v-btn> -->
                <v-btn
                  href="https://wa.me/6281235123518"
                  target="_blank"
                  large
                  color="success"
                >
                  <v-icon left dark>
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
  data: () => ({
    mitra: {
      storeName: "",
    },
    passwordEye: false,
    passwordConfirmEye: false,
    valid: true,
    loading: false,
    storeNameRules: [
      (v) => !!v || "Nama Toko wajib diisi",
      (v) => (v && v.length <= 30) || "Nama Toko wajib kurang dari 30 karakter",
    ],
    ktpNumberRules: [
      (v) => !!v || "Nomor KTP wajib diisi",
      (v) => /[0-9]/.test(v) || "Nomor KTP tidak valid",
      (v) => (v && v.length === 16) || "Nomor KTP wajib 16 karakter",
    ],
    // npwpNumberRules: [
    //   v => !!v || "Nomor NPWP wajib diisi",
    //   v => /[0-9]/.test(v) || "Nomor NPWP tidak valid",
    //   v => (v && v.length === 15) || "Nomor NPWP wajib 15 karakter"
    // ],
    ownerNameRules: [
      (v) => !!v || "Nama Pemilik Toko wajib diisi",
      (v) =>
        (v && v.length <= 30) ||
        "Nama Pemilik Toko wajib kurang dari 30 karakter",
    ],
    emailRules: [
      (v) => !!v || "Email wajib diisi",
      (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
    ],
    phoneNumberRules: [
      (v) => !!v || "Nomor telepon wajib diisi",
      (v) =>
        /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/.test(v) ||
        "Nomor Telepon tidak valid",
    ],
    bankCodeList: [],
    bankCodeRules: [(v) => !!v || "Kode Bank wajib diisi"],
    accountNumberRules: [
      (v) => !!v || "Nomor Rekening wajib diisi",
      (v) => /[0-9]/.test(v) || "Nomor Rekening tidak valid",
    ],
    accountNameRules: [(v) => !!v || "Nama Pemilik Rekening wajib diisi"],
    addressRules: [
      (v) => !!v || "Alamat wajib diisi",
      (v) => (v && v.length <= 150) || "Alamat wajib kurang dari 150 karakter",
    ],
    postalCodeRules: [
      (v) => !!v || "Kode Pos wajib diisi",
      (v) => /[0-9]/.test(v) || "Kode Pos tidak valid",
      (v) => (v && v.length === 5) || "Kode Pos wajib 5 karakter",
    ],
    passwordRules: [
      (v) => !!v || "Password wajib diisi",
      (v) =>
        /^(?=.*[A-Za-z])[A-Za-z\d]{8,12}$/.test(v) ||
        "Setidaknya ada minimal 8 karakter, maximal 12 karakter, dan 1 angka",
    ],
    confirmPasswordRules: [(v) => !!v || "Konfirmasi Password wajib diisi"],
    isAggrement: false,
    mitraImageUrls: [
      "https://jasadesainlogo.id/wp-content/uploads/2020/01/putra-garuda-966x675.png",
      "https://sribu-sg.s3.amazonaws.com/assets/media/contest_detail/2018/1/kontes-logo-untuk-toko-bangunan-5a538a149d68b1631e925e35/normal_223f5948f1.jpg",
      "https://cdn.sribu.com/assets/media/contest_detail/2015/11/toko-bungunan-5653ef78ca6bcb4fb6000003/ea777e0db0.jpg",
      "https://assets.karirpad.com/uploads/employer/logo/2019-07/87c3c379ac74b255cd6dd7049634bb79.png",
      "https://cdn.sribu.com/assets/media/contest_detail/2013/6/logo-untuk-toko-subur-makmur-bangunan-51adae0ea4b1b03fe700013e/4a9ae288c2.png",
      "https://dexpobangunan.files.wordpress.com/2015/06/20688_1388769734775162_1395825442704902278_n.png",
      "https://1.bp.blogspot.com/-3Xi9UOun6LY/XqGWVHB9GJI/AAAAAAADmUo/r5YdjjUglb84pKDQMNJMSUm5gyNGDMz_gCLcBGAsYHQ/s1600/murah%2Bjaya.PNG",
    ],
    dataClients: [
      {
        client: "Toko Sejahterah Maju",
        client_name: "Oscar Rusdiyanto",
        client_picture: "image_person_one.jpg",
        client_logo:
          "https://jasadesainlogo.id/wp-content/uploads/2020/01/putra-garuda-966x675.png",
        message:
          "Setelah bergabung dengan Lajual, toko saya mendapatkan banyak pelanggan baru.",
      },
      {
        client: "Toko Sinar Harapan",
        client_name: "Karel Lutan Warda",
        client_picture: "image_person_two.jpg",
        client_logo:
          "https://sribu-sg.s3.amazonaws.com/assets/media/contest_detail/2018/1/kontes-logo-untuk-toko-bangunan-5a538a149d68b1631e925e35/normal_223f5948f1.jpg",
        message:
          "Saya memperoleh banyak wawasan, seperti cara mengelola keuangan, cara memanagemen aplikasi penjualan, dan saya mendapatkan harga menarik di dalam aplikasi.",
      },
      {
        client: "Toko Putih Abu Abu",
        client_name: "Ivan Ekadharma",
        client_picture: "image_person_three.jpg",
        client_logo:
          "https://cdn.sribu.com/assets/media/contest_detail/2015/11/toko-bungunan-5653ef78ca6bcb4fb6000003/ea777e0db0.jpg",
        message:
          "Sangat senang bisa bergabung dengan Lajual, karena sangat terbantu, khususnya bisa dibantu dalam suplai produk dan ada promo program yang bekerjasama dengan Lajual.",
      },
    ],
  }),

  mounted() {
    this.getBank();
  },

  methods: {
    getBank() {
      CommonService.getAllBank()
        .then((response) => {
          this.bankCodeList = response.data.data;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error",
          });
        });
    },
    submitData() {
      this.loading = true;
      this.$refs.form.validate();

      if (this.mitra.password !== this.mitra.confirmPassword) {
        this.$tostini({
          message: "Password Tidak Sama!",
          type: "error",
        });

        return (this.loading = false);
      }

      const payload = {
        store_name: this.mitra.storeName,
        id_card_number: this.mitra.ktpNumber,
        // npwp_number: this.mitra.npwpNumber,
        owner_name: this.mitra.ownerName,
        email: this.mitra.email,
        phone_number: this.mitra.phoneNumber,
        bank_id: this.mitra.bankCode,
        account_number: this.mitra.accountNumber,
        account_name: this.mitra.accountName,
        address: this.mitra.address,
        postal_code: this.mitra.postalCode,
        password: this.mitra.password,
      };

      CommonService.submitMitra(payload)
        .then((response) => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error",
            });
          } else {
            this.$refs.form.reset();
            this.$tostini({
              message:
                "Pendaftaraan Mitra Berhasil, Data Anda Sedang Diproses oleh Admin Kami, Terima Kasih.",
              type: "success",
              timeout: 2000,
            });
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scrollMeTo(refName) {
      const top = this.$refs[refName].offsetTop;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_twelve.jpg");
  background-size: cover;
  position: relative;
}

.header-2 {
  background-image: url("../../assets/background_five.jpg");
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
