// import dependency to handle HTTP request to our back end
import auth from "./modules/auth";
import product from "./modules/product";
import purchaseBill from "./modules/purchaseBill";
import stock from "./modules/stock";
import sales from "./modules/sales";
import common from "./modules/common";
import report from "./modules/report";
import finance from "./modules/finance";
import operator from "./modules/operator";
import accounting from "./modules/accounting";
import invoice from "./modules/invoice";
import airwayBill from "./modules/airwayBill";
import mitraDashboard from "./modules/mitraDashboard";
import Vuex from "vuex";
import Vue from "vue";

//load Vuex
Vue.use(Vuex);

//to handle state
const state = {
  loading: false,
  message: ""
};

//to handle state
const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMessage(state) {
    return state.message;
  }
};

//to handle actions
const actions = {
  setLoading({ commit, statusLoading }) {
    commit("SET_LOADING", statusLoading);
  },
  setMessage({ commit, message }) {
    commit("SET_MESSAGE", message);
  }
};

//to handle mutations
const mutations = {
  SET_LOADING(state, statusLoading) {
    state.loading = statusLoading;
  },
  SET_MESSAGE(state, message) {
    state.message = message;
  }
};

//export store module
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    auth,
    product,
    purchaseBill,
    stock,
    sales,
    common,
    report,
    finance,
    operator,
    accounting,
    mitraDashboard,
    invoice,
    airwayBill
  }
});
