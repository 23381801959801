import StockService from "@/services/StockService";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

const stock = {
  namespaced: true,
  state: {
    stocks: [],
    categoryList: []
  },
  getters: {
    getStocks(state) {
      return state.stocks;
    },
    getCategoryList(state) {
      return state.categoryList;
    }
  },
  actions: {
    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        StockService.getAll()
          .then(response => {
            commit("GET_STOCKS", response.data?.data);
            commit("GET_CATEGORY_LIST", response.data?.category_list);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        StockService.getBySearch(params)
          .then(response => {
            const payload = response.data?.data;
            commit("GET_STOCKS", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_STOCKS: (state, payload) => {
      state.stocks = payload;
    },
    GET_CATEGORY_LIST: (state, payload) => {
      state.categoryList = payload;
    }
  }
};

export default stock;
