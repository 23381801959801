//error message
export const ERROR_SOMETHING_WRONG = "Terjadi Kesalahan";
export const ERROR_UPLOAD_FAILED = "Gagal Upload!";
export const ERROR_FILE_FORMAT = "Format File yang anda masukkan salah!";
export const ERROR_DATA_NOT_FOUND = "Data Tidak Ditemukan";

//success message
export const SUCCESS_UPLOAD = "Berhasil Upload";
export const SUCCESS_CHANGE_STATUS = "Status Berhasil Diubah";
export const SUCCESS_DOWNLOAD_DATA = "Data Berhasil Didownload";
export const SUCCESS_CHANGE_PRODUCT = "Data Berhasil Diubah";
export const SUCCESS_ADD_AIRWAY_BILL = "Surat Jalan Berhasil Dibuat";
