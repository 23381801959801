import SalesService from "@/services/SalesService";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

const sales = {
  namespaced: true,
  state: {
    sales: [],
    salesDetail: {}
  },
  getters: {
    getSales(state) {
      return state.sales;
    },
    getSalesDetail(state) {
      return state.salesDetail;
    }
  },
  actions: {
    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        SalesService.getAll()
          .then(response => {
            const payload = response.data?.data;
            commit("GET_SALES", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getById({ commit }, id) {
      return new Promise((resolve, reject) => {
        SalesService.getDetail(id)
          .then(response => {
            const payload = response.data?.data;
            commit("GET_SALES_DETAIL", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          });
      });
    },
    getBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        SalesService.getBySearch(params)
          .then(response => {
            if (response.data?.length === 0) {
              commit("SET_MESSAGE", response.data?.message, {
                root: true
              });
              reject();
            } else {
              commit("GET_SALES", response.data?.data);
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportSales({ commit }, trxNo) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        SalesService.printReceipt(trxNo)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", trxNo + ".pdf");
            document.body.appendChild(link);
            link.click();
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_SALES: (state, payload) => {
      state.sales = payload;
    },
    GET_SALES_DETAIL: (state, payload) => {
      state.salesDetail = payload;
    }
  }
};

export default sales;
