import api from "@/services/ApiService";

export default {
  getAnnualTransaction() {
    return api().post("/dashboard/annual_transaction");
  },
  getTopTransaction() {
    return api().post("/dashboard/top_transaction");
  },
  getLatestTransaction() {
    return api().post("/dashboard/latest_transaction");
  },
  getLessStock() {
    return api().post("/dashboard/product_out_of_stock");
  },
  getMoreStock() {
    return api().post("/dashboard/product_alot_stock");
  },
  getOperator() {
    return api().post("/dashboard/operator_summary");
  },
  getMonthlyProgress() {
    return api().post("/dashboard/monthly_progress");
  }
};
