<template>
  <div id="RegisterSupplier">
    <!-- <div class="header-1 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-16">
          <div class="secondary--text text-h5 text-md-h2 pa-5">
            Bergabung Menjadi
          </div>
          <div class="secondary--text text-h4 text-md-h1 pa-5">
            Supplier
          </div>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-one">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex">
              <v-img
                alt="Contact Us"
                src="@/assets/icon_supplier.jpg"
                class="mx-auto"
                max-height="500"
                max-width="500"
              />
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title>
                  <div class="text-center text-lg-left text-h6 text-lg-h5 mt-5">
                    Kemudahan Melakukan Penawaran dan Penjualan
                  </div>
                </v-card-title>
                <v-card-text>
                  <p class="text-center text-lg-left">
                    Bergabung Menjadi Supplier Membantu Anda Mengembangkan
                    Konsumen Baru Dan Mengefisienkan Proses dengan Teknologi
                    Digital
                  </p>
                  <v-row class="justify-center text-center mb-5">
                    <v-btn outlined color="info" class="ma-2">Nyaman</v-btn>
                    <v-btn outlined color="info" class="ma-2">Mudah</v-btn>
                    <v-btn outlined color="info" class="ma-2">Terpercaya</v-btn>
                  </v-row>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Sistem Penjualan Online (Efisiensi Biaya)</v-chip
                    >
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Meminimalisir Kesalahan Manusia</v-chip
                    >
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5">Realtime</v-chip>
                  </div>
                  <div>
                    <v-chip outlined class="ma-2 px-5"
                      >Promosi dan Memperluas Pasar</v-chip
                    >
                  </div>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn
                      large
                      color="info"
                      @click="scrollMeTo('registration')"
                      >Daftar Sekarang</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-two secondary">
      <v-container py-16>
        <div>
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Keuntungan Bergabung Menjadi Supplier
          </div>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="550" width="350">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_supplier.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Sistem Penjualan Online (Efisiensi Biaya)
                </v-card-subtitle>
                <v-card-text
                  >Dengan menggunakan system penjualan online yang terintegrasi
                  dengan toko material, supplier dapat melakukan penghematan
                  biaya
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="550" width="350">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_supplier.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Meminimalisir Kesalahan Manusia
                </v-card-subtitle>
                <v-card-text
                  >Penggunaan teknologi secara digital dapat mengurangi secara
                  signifikan kesalahan dan kecurangan yang mungkin dilakukan
                  oleh manusia
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="550" width="350">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_supplier.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Real Time
                </v-card-subtitle>
                <v-card-text
                  >Supplier dapat memantau data dan proses penjualan saat itu
                  juga secara mumpuni
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="550" width="350">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_supplier.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Promosi dan Memperluas Pasar
                </v-card-subtitle>
                <v-card-text
                  >Salurkan promosi anda kesasaran yang tepat berdasarkan data
                  yang akurat
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-three info">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="justify-center">
            <div class="secondary--text text-h5 text-lg-h4 mb-16">
              Mitra Kami
            </div>
          </v-row>
          <v-row class="justify-center">
            <div v-for="(mitraUrl, i) in mitraImageUrls" :key="i">
              <v-img
                :lazy-src="mitraUrl"
                alt="Client Picture"
                :src="mitraUrl"
                class="ma-5"
                height="200"
                width="200"
              />
            </div>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-three info">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="justify-center">
            <div class="secondary--text text-h5 text-lg-h4 mb-16">
              Mitra Kami
            </div>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-four d-none d-md-flex">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row justify="center">
            <div class="text-h5 text-lg-h4 mb-10">
              Selalu Dipercaya Oleh Klien Kami
            </div>
          </v-row>

          <v-row justify="center">
            <div class="text-h5 text-lg-h6 mb-10">
              Dipercaya lebih dari
              <span class="primary--text">40.000</span> pebisnis di seluruh
              Indonesia
            </div>
          </v-row>

          <v-row>
            <v-carousel cycle hide-delimiters height="500" class="mx-16">
              <v-carousel-item v-for="(data, i) in dataClients" :key="i">
                <v-card class="mx-16 mt-10 secondary">
                  <div class="d-flex justify-space-between">
                    <div class="pa-5">
                      <v-img
                        alt
                        width="100"
                        height="100"
                        class="ml-2"
                        src="@/assets/logo_example.jpg"
                      ></v-img>
                      <v-card-title>
                        <div class="black--text text-h6">
                          {{ data.client_name }}
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="black--text text-caption">
                          Pemilik dari {{ data.client }}
                        </div>
                        <p class="black--text font-weight-bold mt-8">
                          {{ `" ${data.message} "` }}
                        </p>
                      </v-card-text>
                    </div>
                    <div class="d-none d-md-inline-flex">
                      <v-img
                        lazy-src="@/assets/image_person_one.jpg"
                        alt="Client Picture"
                        src="@/assets/image_person_one.jpg"
                        height="450"
                        width="450"
                      />
                    </div>
                  </div>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-five">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="justify-center">
            <div class="text-center text-h5 text-lg-h4 mx-5 mb-16">
              Mari Membangun Era Baru Bersama
            </div>
          </v-row>
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex"
              ><v-img
                alt="Contact Us"
                src="@/assets/image_technology.jpg"
                class="mx-auto"
                max-height="500"
                max-width="500"
                transition="scale-transition"
            /></v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title class="justify-center">
                  <div class="text-h6 text-center text-md-left text-lg-h5 mt-5">
                    Bangun bisnis anda yang tersistematis dengan teknologi
                  </div>
                </v-card-title>
                <v-card-text>
                  <p
                    class="black--text font-weight-bold text-center text-md-left"
                  >
                    Jadilah yang pertama bergabung
                  </p>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn
                      large
                      color="info"
                      @click="scrollMeTo('registration')"
                      >Daftar Sekarang</v-btn
                    >
                  </v-row>

                  <v-row
                    class="justify-center justify-lg-start mt-10 mb-5 mx-0"
                  >
                    <v-sheet class="secondary--text py-2 px-5" color="success">
                      <div>
                        <v-icon left dark>
                          mdi-whatsapp
                        </v-icon>
                        +62 812 126 126 86
                      </div>
                    </v-sheet>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <div class="section-six" ref="registration">
      <v-container class="ext" py-16>
        <div class="mx-5">
          <v-row class="justify-center">
            <div class="text-center text-h5 text-lg-h4 mb-16">
              Pendaftaran Menjadi Supplier
            </div>
          </v-row>
          <!-- <v-row>
            <div class="text-h6">
              Syarat Bergabung
            </div>
          </v-row>
          <ul class="mb-16">
            <li>Berdomisili di wilayah Jakarta, dan sekitarnya.</li>
            <li>Memiliki Bangunan Permanen</li>
          </ul> -->
          <v-form ref="form" v-model="valid" autocomplete="off">
            <v-row>
              <v-col class="col-12 col-md-7">
                <v-text-field
                  v-model="supplier.companyName"
                  :counter="100"
                  :rules="companyNameRules"
                  label="Nama Usaha/ Perusahaan*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="supplier.companyAddress"
                  :counter="150"
                  :rules="companyAddressRules"
                  label="Alamat Usaha/ Perusahaan*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="supplier.postalCode"
                  :counter="5"
                  :rules="postalCodeRules"
                  label="Kode Pos*"
                  v-mask="['#####']"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="supplier.workingArea"
                  :rules="workingAreaRules"
                  label="Cakupan Area Wilayah Kerja*"
                  clearable
                  required
                ></v-text-field>
                <v-container fluid>
                  <p>Status Perusahaan (PKP/ Non-PKP)*</p>
                  <v-radio-group v-model="supplier.companyStatus" row mandatory>
                    <v-radio label="PKP" value="PKP"></v-radio>
                    <v-radio label="Non PKP" value="Non PKP"></v-radio>
                  </v-radio-group>
                </v-container>
                <v-text-field
                  v-model="supplier.responsibleName"
                  :rules="responsibleNameRules"
                  label="Nama Penanggung Jawab*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="supplier.responsibleEmail"
                  :rules="responsibleEmailRules"
                  label="Email Perusahaan*"
                  clearable
                  required
                ></v-text-field>
                <div class="text-caption">
                  *Email yang didaftarkan akan digunakan sebagai akses pada web
                  lajual
                </div>
                <v-text-field
                  v-model="supplier.responsiblePhone"
                  :counter="12"
                  :rules="responsiblePhoneRules"
                  label="Nomor Handphone*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="supplier.ktpNumber"
                  :counter="16"
                  :rules="ktpNumberRules"
                  label="Nomor KTP/ Upload KTP*"
                  clearable
                  required
                ></v-text-field>
                <v-file-input
                  show-size
                  label="Masukkan Foto KTP*"
                  v-model="supplier.ktpFile"
                  :rules="ktpFileRules"
                ></v-file-input>
                <v-text-field
                  v-model="supplier.npwpNumber"
                  :counter="16"
                  :rules="npwpNumberRules"
                  label="NPWP Perusahaan atau NPWP Pribadi Penanggung Jawab*"
                  clearable
                  required
                ></v-text-field>
                <v-file-input
                  show-size
                  label="Masukkan Foto NPWP*"
                  v-model="supplier.npwpFile"
                  :rules="npwpFileRules"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="col-7">
              <v-btn
                :disabled="loading"
                :loading="loading"
                color="success"
                class="px-10"
                @click="onSubmitClick"
                >Kirim</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-seven header-2 secondary">
      <div class="black-background"></div>
      <v-container class="ext">
        <div>
          <div
            class="secondary--text text-center text-lg-left text-h5 text-lg-h4 mt-4"
          >
            Masih ada pertanyaan?
          </div>
          <v-row>
            <v-col>
              <v-row class="justify-center justify-md-start my-8 mx-0">
                <!-- <v-btn x-large color="info" to="/contactUs">Hubungi Kami</v-btn> -->
                <v-btn
                  href="https://wa.me/6281235123518"
                  target="_blank"
                  large
                  color="success"
                >
                  <v-icon left dark>
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
  data() {
    return {
      supplier: {},
      valid: true,
      loading: false,
      mitraImageUrls: [
        "https://jasadesainlogo.id/wp-content/uploads/2020/01/putra-garuda-966x675.png",
        "https://sribu-sg.s3.amazonaws.com/assets/media/contest_detail/2018/1/kontes-logo-untuk-toko-bangunan-5a538a149d68b1631e925e35/normal_223f5948f1.jpg",
        "https://cdn.sribu.com/assets/media/contest_detail/2015/11/toko-bungunan-5653ef78ca6bcb4fb6000003/ea777e0db0.jpg",
        "https://assets.karirpad.com/uploads/employer/logo/2019-07/87c3c379ac74b255cd6dd7049634bb79.png",
        "https://cdn.sribu.com/assets/media/contest_detail/2013/6/logo-untuk-toko-subur-makmur-bangunan-51adae0ea4b1b03fe700013e/4a9ae288c2.png",
        "https://dexpobangunan.files.wordpress.com/2015/06/20688_1388769734775162_1395825442704902278_n.png",
        "https://1.bp.blogspot.com/-3Xi9UOun6LY/XqGWVHB9GJI/AAAAAAADmUo/r5YdjjUglb84pKDQMNJMSUm5gyNGDMz_gCLcBGAsYHQ/s1600/murah%2Bjaya.PNG",
      ],
      dataClients: [
        {
          client: "Toko Sejahterah Maju",
          client_name: "Oscar Rusdiyanto",
          client_picture: "image_person_one.jpg",
          client_logo:
            "https://jasadesainlogo.id/wp-content/uploads/2020/01/putra-garuda-966x675.png",
          message:
            "Setelah bergabung dengan Lajual, toko saya mendapatkan banyak pelanggan baru.",
        },
        {
          client: "Toko Sinar Harapan",
          client_name: "Karel Lutan Warda",
          client_picture: "image_person_two.jpg",
          client_logo:
            "https://sribu-sg.s3.amazonaws.com/assets/media/contest_detail/2018/1/kontes-logo-untuk-toko-bangunan-5a538a149d68b1631e925e35/normal_223f5948f1.jpg",
          message:
            "Saya memperoleh banyak wawasan, seperti cara mengelola keuangan, cara memanagemen aplikasi penjualan, dan saya mendapatkan harga menarik di dalam aplikasi.",
        },
        {
          client: "Toko Putih Abu Abu",
          client_name: "Ivan Ekadharma",
          client_picture: "image_person_three.jpg",
          client_logo:
            "https://cdn.sribu.com/assets/media/contest_detail/2015/11/toko-bungunan-5653ef78ca6bcb4fb6000003/ea777e0db0.jpg",
          message:
            "Sangat senang bisa bergabung dengan Lajual, karena sangat terbantu, khususnya bisa dibantu dalam suplai produk dan ada promo program yang bekerjasama dengan Lajual.",
        },
      ],
      companyNameRules: [
        (v) => !!v || "Nama Perusahaan wajib diisi",
        (v) =>
          (v && v.length <= 100) ||
          "Nama Perusahaan wajib kurang dari 100 karakter",
      ],
      companyAddressRules: [
        (v) => !!v || "Alamat Perusahaan wajib diisi",
        (v) =>
          (v && v.length <= 150) || "Alamat wajib kurang dari 150 karakter",
      ],
      postalCodeRules: [
        (v) => !!v || "Kode Pos wajib diisi",
        (v) => /[0-9]/.test(v) || "Kode Pos tidak valid",
        (v) => (v && v.length === 5) || "Kode Pos wajib 5 karakter",
      ],
      workingAreaRules: [
        (v) => !!v || "Cakupan Area Wilayah Kerja wajib diisi",
      ],
      responsibleNameRules: [(v) => !!v || "Nama Penanggung Jawab wajib diisi"],
      responsibleEmailRules: [
        (v) => !!v || "Email Penanggung Jawab wajib diisi",
        (v) => /.+@.+\..+/.test(v) || "Email tidak valid",
      ],
      responsiblePhoneRules: [
        (v) => !!v || "Nomor Handphone Penanggung Jawab wajib diisi",
        (v) =>
          /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/.test(v) ||
          "Nomor Handphone tidak valid",
      ],
      ktpNumberRules: [
        (v) => !!v || "Nomor KTP wajib diisi",
        (v) => /^[0-9]{1,16}$/.test(v) || "Nomor KTP tidak valid",
        (v) => (v && v.length === 16) || "Nomor KTP wajib 16 karakter",
      ],
      ktpFileRules: [(v) => !!v || "Foto KTP wajib diisi"],
      npwpNumberRules: [
        (v) => !!v || "Nomor NPWP wajib diisi",
        (v) => /^[0-9]{1,16}$/.test(v) || "Nomor NPWP tidak valid",
        (v) => (v && v.length === 16) || "Nomor NPWP wajib 16 karakter",
      ],
      npwpFileRules: [(v) => !!v || "Foto NPWP wajib diisi"],
    };
  },
  methods: {
    scrollMeTo(refName) {
      const top = this.$refs[refName].offsetTop;
      window.scrollTo(0, top);
    },
    onSubmitClick() {
      this.loading = true;
      this.$refs.form.validate();

      const formData = new FormData();
      formData.append("company_name", this.supplier.companyName);
      formData.append("company_address", this.supplier.companyAddress);
      formData.append("postal_code", this.supplier.postalCode);
      formData.append("working_area", this.supplier.workingArea);
      formData.append("company_status", this.supplier.companyStatus);
      formData.append("responsible_name", this.supplier.responsibleName);
      formData.append("email", this.supplier.responsibleEmail);
      formData.append("phone_number", this.supplier.responsiblePhone);
      formData.append("id_card_number", this.supplier.ktpNumber);
      formData.append("attachment_ktp", this.supplier.ktpFile);
      formData.append("npwp", this.supplier.npwpNumber);
      formData.append("attachment_npwp", this.supplier.npwpFile);

      CommonService.submitSupplier(formData)
        .then((response) => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error",
            });
          } else {
            this.$refs.form.reset();
            this.$tostini({
              message:
                "Pendaftaraan Supplier Berhasil, Data Anda Sedang Diproses oleh Admin Kami, Terima Kasih.",
              type: "success",
              timeout: 2000,
            });
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_nine.jpg");
  background-size: cover;
  position: relative;
}

.header-2 {
  background-image: url("../../assets/background_five.jpg");
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
