<template>
  <div id="Landing">
    <div class="header-1 secondary">
      <v-container>
        <!-- <div class="secondary--text text-h4 text-lg-h2 pa-5">
          {{ $t("sec1Title") }}
        </div> -->
        <v-row>
          <v-col class="col-7">
            <div class="secondary--text text-h4 text-lg-h3 pa-5">
              Ekosistem Digital Terlengkap Untuk Memulai Bisnis
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="col-12 text-center text-lg-left">
            <div class="secondary--text text-subtitle-1 text-lg-h5 px-5">
              Kemudahan Mengelolah Bisnis dan Toko Anda
            </div>
            <!-- <div class="secondary--text text-subtitle-1 text-lg-h5 mt-2 px-5">
              Asisten Terpercaya Merenovasi Rumah
            </div> -->
            <div class="secondary--text text-subtitle-1 text-lg-h5 px-5">
              Era Baru Sistem Penjualan Bagi Supplier
            </div>
            <!-- <div class="secondary--text text-subtitle-1 text-lg-h5 mt-2 px-5">
              Partner Pengembangan Usaha Digital Jasa Kontraktor & Design
            </div> -->
          </v-col>
        </v-row>

        <v-row class="py-4">
          <v-col class="col-12 col-lg-4 col-sm-6 text-center text-sm-right">
            <v-btn outlined large color="primary">#MEMBANGUNINDONESIA</v-btn>
          </v-col>
          <v-col class="col-12 col-lg-4 col-sm-6 text-center text-sm-left">
            <v-btn outlined large color="primary">#KARYAANAKBANGSA</v-btn>
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col class="col-12 col-lg-8 col-sm-6 text-center text-lg-center">
            <v-btn
              large
              class="secondary--text px-10"
              color="#156874"
              @click="scrollMeTo('ourService')"
              rounded
              >Daftar Sekarang</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- <div class="section-one secondary">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Ekosistem Digital Terlengkap Untuk Memulai Bisnis Indonesia
          </div>
          <p class="text-center font-weight-bold">
            Membantu Anda Mengembangkan Bisnis Anda
          </p>
          <v-row class="justify-center text-center">
            <v-btn outlined large color="info" class="ma-5">Nyaman</v-btn>
            <v-btn outlined large color="info" class="ma-5">Mudah</v-btn>
            <v-btn outlined large color="info" class="ma-5">Terpercaya</v-btn>
          </v-row>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_owner.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Pemilik Toko</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_supplier.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Supplier</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_designer.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Designer</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="200"
                    max-width="200"
                  />
                </v-card-title>
                <v-list-item align="center">
                  <v-list-item-content>
                    <v-list-item-title class="text-h6"
                      >Jasa Renovasi</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-two header-2 secondary">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div
            class="primary--text text-h5 text-lg-h4 text-center text-lg-left mt-5 mb-10"
          >
            Apa Yang Membuat Kami Berbeda ?
          </div>
          <v-row>
            <v-col class="col-12 col-lg-8">
              <div
                class="primary--text text-center text-lg-left text-subtitle-1 text-lg-h6 my-5"
              >
                Lajual merupakan sebuah aplikasi teknologi penggembangan
                ekosistem industri material bangunan, yang memberikan nilai
                tambah signifikan kepada para supplier, mitra dan pengguna jasa
                renovasi, melalui kemudahan supplier dalam memperluas pemasaran,
                pemanfaatan teknologi management toko bagi mitra, dan
                menyediakan kebutuhan dan konsultasi terpercaya untuk menjawab
                kebutuhan pengguna akan jasa renovasi.
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-three">
      <v-container class="ext" py-16>
        <v-row class="py-16 justify-space-around">
          <v-col class="col-12 col-md-5">
            <v-card class="mx-auto px-5">
              <v-card-title class="justify-center justify-md-start">
                <div class="text-h5 text-lg-h4 mt-5 mb-10">
                  Apa Bisnis Anda ?
                </div>
              </v-card-title>
              <v-card-text>
                <p
                  class="black--text font-weight-bold text-center text-md-left"
                >
                  Kami Siap Untuk Membantu Anda Menemukan Solusi Yang Anda Cari.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="col-5 d-none d-md-inline-flex"
            ><v-img
              alt="Contact Us"
              src="@/assets/image_contact_us.jpg"
              class="mx-auto"
              max-height="500"
              max-width="500"
              transition="scale-transition"
          /></v-col>
        </v-row>
      </v-container>
    </div> -->

    <!-- <div class="section-four info" ref="ourService">
      <v-container py-16>
        <v-row class="justify-center">
          <div class="secondary--text text-h5 text-lg-h4 mb-10">
            Layanan Kami
          </div>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mx-auto mt-10 secondary">
              <div class="d-flex justify-space-between">
                <div class="pa-5">
                  <v-card-title>
                    <div class="black--text text-h6">
                      Sistem Managemen Bisnis dan Toko
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <p class="black--text my-8">
                      Anda pemilik toko bangunan dan selalu bekerja di toko
                      setiap hari? Saatnya membuat toko anda tersistematis.
                    </p>

                    <p class="font-weight-bold mb-n1">Manfaat:</p>

                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Sistem Kasir
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Kelola Barang di Toko
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Order Barang ke Supplier
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Meningkatkan Jumlah Pelanggan
                    </div>
                  </v-card-text>
                  <v-btn
                    to="/registerMitra"
                    large
                    color="info"
                    class="ml-5 mt-5"
                    >Klik Disini</v-btn
                  >
                </div>
                <div class="d-none d-md-inline-flex align-center">
                  <v-img
                    lazy-src="@/assets/icon_custom_consumer.png"
                    alt="Client Picture"
                    src="@/assets/icon_custom_consumer.png"
                    class="mx-10"
                    height="200"
                    width="200"
                  />
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto mt-10 secondary">
              <div class="d-flex justify-space-between">
                <div class="pa-5">
                  <v-card-title>
                    <div class="black--text text-h6">
                      Asisten Renovasi Rumahmu
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <p class="black--text my-8">
                      Anda butuh renovasi rumah dan sulit mencari tukang? atau
                      kesulitan menentukan biaya renovasi yang optimal? Kami ada
                      disini untuk anda, Percayakan pada Asisten Renovasi
                      Lajual.
                    </p>

                    <p class="font-weight-bold mb-n1">Manfaat:</p>

                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Hasil Kerja Profesional
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Kontraktor Terpercaya
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Asisten Pengawas dan Pendampingan
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Rencana Anggaran dan Harga terbaik
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Bergaransi
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Aman dan Terpercaya
                    </div>
                  </v-card-text>
                  <v-btn
                    to="/registerAssistant"
                    large
                    color="info"
                    class="ml-5 mt-5"
                    >Klik Disini</v-btn
                  >
                </div>
                <div class="d-none d-md-inline-flex align-center">
                  <v-img
                    lazy-src="@/assets/icon_custom_designer.png"
                    alt="Client Picture"
                    src="@/assets/icon_custom_designer.png"
                    class="mx-10"
                    height="200"
                    width="200"
                  />
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mx-auto mt-10 secondary">
              <div class="d-flex justify-space-between">
                <div class="pa-5">
                  <v-card-title>
                    <div class="black--text text-h6">
                      Bergabung Menjadi Supplier
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <p class="black--text my-8">
                      Anda supplier bahan bangunan dan masih menggunakan sistem
                      penjualan yang konvensional? Saatnya bergabung di Era Baru
                      Sistem Penjualan secara Digital.
                    </p>

                    <p class="font-weight-bold mb-n1">Manfaat:</p>

                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Sistem Penjualan Online (Efisiensi Biaya)
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Meminimalisir Kesalahan Manusia
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Real Time
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Promosi dan Memperluas Pasar
                    </div>
                  </v-card-text>
                  <v-btn
                    to="/registerSupplier"
                    large
                    color="info"
                    class="ml-5 mt-5"
                    >Klik Disini</v-btn
                  >
                </div>
                <div class="d-none d-md-inline-flex align-center">
                  <v-img
                    lazy-src="@/assets/icon_custom_supplier.png"
                    alt="Client Picture"
                    src="@/assets/icon_custom_supplier.png"
                    class="mx-10"
                    height="200"
                    width="200"
                  />
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col>
            <v-card class="mx-auto mt-10 secondary">
              <div class="d-flex justify-space-between">
                <div class="pa-5">
                  <v-card-title>
                    <div class="black--text text-h6">
                      Bergabung Menjadi Kontraktor dan Designer
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <p class="black--text my-8">
                      Anda kontraktor dan designer yang membutuhkan partner
                      dalam mengembangkan usaha? Bertemu dengan berbagai peluang
                      tak terbatas dengan Customer Baru. Ayo Bergabung.
                    </p>

                    <p class="font-weight-bold mb-n1">Manfaat:</p>

                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Sistem Online
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Hemat Waktu dan Biaya
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Pembayaran Tepat Waktu
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Adanya Kontrak Jasa Yang Terpercaya
                    </div>
                    <div>
                      <v-icon small left color="info">
                        mdi-check
                      </v-icon>
                      Memperluas Pasar Anda
                    </div>
                  </v-card-text>
                  <v-btn to="/contactUs" large color="info" class="ml-5 mt-5"
                    >Hubungi Kami</v-btn
                  >
                </div>
                <div class="d-none d-md-inline-flex align-center">
                  <v-img
                    lazy-src="@/assets/icon_custom_contractor.png"
                    alt="Client Picture"
                    src="@/assets/icon_custom_contractor.png"
                    class="mx-10"
                    height="200"
                    width="200"
                  />
                </div>
              </div>
            </v-card>
          </v-col>

          Comment it latter
          <v-col
              v-for="(data, i) in ourServices"
              :key="i"
              class="col-12 col-lg-6 d-flex justify-center"
            >
              <v-card class="mx-auto mt-10 secondary" max-width="700">
                <div class="d-flex justify-space-between">
                  <div class="pa-5">
                    <v-card-title>
                      <div class="black--text text-h6">
                        {{ data.service_title }}
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <p class="black--text my-8">
                        {{ data.service_desc }}
                      </p>

                      <p class="font-weight-bold mb-n1">Manfaat:</p>
                      <v-sheet
                        v-for="(item, i) in data.service_feature"
                        :key="i"
                        elevation="1"
                        rounded
                        class="ma-3 pa-1"
                        ><v-icon small left color="info">
                          mdi-check
                        </v-icon>
                        <span class="info--text">{{ item }}</span></v-sheet
                      >
                      <v-chip class="my-2" outlined style="width: auto;">
                          <v-icon small left>
                            mdi-check
                          </v-icon>
                          {{ item }}
                        </v-chip>
                    </v-card-text>
                    <v-btn
                      :to="data.service_route"
                      large
                      color="info"
                      class="ml-4"
                      >{{ data.service_button }}</v-btn
                    >
                  </div>
                  <div class="d-none d-md-inline-flex align-center">
                    <v-img
                      lazy-src="@/assets/icon_service_one.png"
                      alt="Client Picture"
                      src="@/assets/icon_service_one.png"
                      class="mx-10"
                      height="150"
                      width="150"
                    />
                  </div>
                </div>
              </v-card>
            </v-col>
        </v-row>
      </v-container>
    </div> -->

    <!-- <div class="section-five">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="justify-center">
            <div class="text-center text-h5 text-lg-h4 mx-5 mb-16">
              Mari Membangun Era Baru Bersama
            </div>
          </v-row>
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex"
              ><v-img
                alt="Contact Us"
                src="@/assets/image_technology.jpg"
                class="mx-auto"
                max-height="500"
                max-width="500"
                transition="scale-transition"
            /></v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title class="justify-center">
                  <div class="text-h6 text-center text-md-left text-lg-h5 mt-5">
                    Bangun bisnis anda yang tersistematis dengan teknologi
                  </div>
                </v-card-title>
                <v-card-text>
                  <p
                    class="black--text font-weight-bold text-center text-md-left"
                  >
                    Jadilah yang pertama bergabung, dan dapatkan manfaatnya
                    sebagai pemain pertama
                  </p>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn
                      x-large
                      color="info"
                      @click="scrollMeTo('ourService')"
                      >Daftar Sekarang</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-six info">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="justify-center">
            <div class="secondary--text text-h5 text-lg-h4 mb-16">
              Mitra Kami
            </div>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
            <v-col class="d-flex justify-center">
              <v-img
                lazy-src="@/assets/logo_example.jpg"
                src="@/assets/logo_example.jpg"
                class="ma-5"
                max-height="120"
                max-width="120"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-seven d-none d-md-flex">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row justify="center">
            <div class="text-center text-h5 text-lg-h4 mx-5 mb-10">
              Selalu Dipercaya Oleh Klien Kami
            </div>
          </v-row>
          <v-row>
            <v-carousel cycle hide-delimiters height="500" class="mx-16">
              <v-carousel-item v-for="(data, i) in dataClients" :key="i">
                <v-card class="mx-16 mt-10 secondary">
                  <div class="d-flex justify-space-between">
                    <div class="pa-5">
                      <v-img
                        alt
                        width="100"
                        height="100"
                        class="ml-2"
                        src="@/assets/logo_example.jpg"
                      ></v-img>
                      <v-card-title>
                        <div class="black--text text-h6">
                          {{ data.client_name }}
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <div class="black--text text-caption">
                          Pemilik dari {{ data.client }}
                        </div>
                        <p class="black--text font-weight-bold mt-8">
                          {{ `" ${data.message} "` }}
                        </p>
                      </v-card-text>
                    </div>
                    <div class="d-none d-md-inline-flex">
                      <v-img
                        lazy-src="@/assets/image_person_one.jpg"
                        alt="Client Picture"
                        src="@/assets/image_person_one.jpg"
                        height="450"
                        width="450"
                      />
                    </div>
                  </div>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <!-- <div class="section-eight header-3 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-10">
          <div
            class="secondary--text text-h5 text-lg-h4 text-center text-lg-left mt-5 mb-10"
          >
            Perlu Konsultasi?
          </div>
          <v-row>
            <v-col class="col-12 col-lg-6">
              <div class="text-center text-lg-left">
                <p class="secondary--text font-weight-bold my-5">
                  Dapatkan Dukungan Dari Ahlinya, Hubungi Kami Melalui Whatsapp
                  Sekarang,
                </p>
              </div>
              <v-row class="justify-center justify-lg-start mt-10 mb-5 mx-0">
                comment this later
                <v-btn
                  href="https://wa.me/6282227004385"
                  target="_blank"
                  x-large
                  color="success"
                >
                  <v-icon left dark>
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
                <v-sheet class="secondary--text py-2 px-5" color="success">
                  <div>
                    <v-icon left dark>
                      mdi-whatsapp
                    </v-icon>
                    +62 812 126 126 86
                  </div>
                </v-sheet>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div> -->

    <div class="section-seven header-2 secondary">
      <div class="black-background"></div>
      <v-container class="ext">
        <div>
          <div
            class="secondary--text text-center text-lg-left text-h5 text-lg-h4 mt-4"
          >
            Masih ada pertanyaan?
          </div>
          <v-row>
            <v-col>
              <v-row class="justify-center justify-md-start my-8 mx-0">
                <!-- <v-btn x-large color="info" to="/contactUs">Hubungi Kami</v-btn> -->
                <v-btn
                  href="https://wa.me/6281235123518"
                  target="_blank"
                  large
                  color="success"
                >
                  <v-icon left dark>
                    mdi-whatsapp
                  </v-icon>
                  Whatsapp
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataClients: [
        {
          client: "Toko Sejahterah Maju",
          client_name: "Oscar Rusdiyanto",
          client_picture: "image_person_one.jpg",
          client_logo:
            "https://jasadesainlogo.id/wp-content/uploads/2020/01/putra-garuda-966x675.png",
          message:
            "Setelah bergabung dengan Lajual, toko saya mendapatkan banyak pelanggan baru.",
        },
        {
          client: "Toko Sinar Harapan",
          client_name: "Karel Lutan Warda",
          client_picture: "image_person_two.jpg",
          client_logo:
            "https://sribu-sg.s3.amazonaws.com/assets/media/contest_detail/2018/1/kontes-logo-untuk-toko-bangunan-5a538a149d68b1631e925e35/normal_223f5948f1.jpg",
          message:
            "Saya memperoleh banyak wawasan, seperti cara mengelola keuangan, cara memanagemen aplikasi penjualan, dan saya mendapatkan harga menarik di dalam aplikasi.",
        },
        {
          client: "Toko Putih Abu Abu",
          client_name: "Ivan Ekadharma",
          client_picture: "image_person_three.jpg",
          client_logo:
            "https://cdn.sribu.com/assets/media/contest_detail/2015/11/toko-bungunan-5653ef78ca6bcb4fb6000003/ea777e0db0.jpg",
          message:
            "Sangat senang bisa bergabung dengan Lajual, karena sangat terbantu, khususnya bisa dibantu dalam suplai produk dan ada promo program yang bekerjasama dengan Lajual.",
        },
      ],
      ourServices: [
        {
          service_title: "Sistem Managemen Bisnis dan Toko",
          service_desc:
            "Anda pemilik toko bangunan dan selalu bekerja di toko setiap hari? Saatnya membuat toko anda tersistematis.",
          service_feature: [
            "Sistem Kasir",
            "Kelola Barang di Toko",
            "Order Barang ke Supplier",
            "Meningkatkan Jumlah Pelanggan",
          ],
          service_route: "/registerMitra",
          service_img_url: "icon_service_one.png",
          service_button: "Klik Disini",
        },
        {
          service_title: "Asisten Renovasi Rumahmu",
          service_desc:
            "Anda butuh renovasi rumah dan sulit mencari tukang? atau kesulitan menentukan biaya renovasi yang optimal? Kami ada disini untuk anda, Percayakan pada Asisten Renovasi Lajual. ",
          service_feature: [
            "Hasil Kerja Profesional",
            "Kontraktor Terpercaya",
            "Asisten Pengawas dan Pendampingan",
            "Rencana Anggaran dan Harga terbaik",
            "Bergaransi",
            "Aman dan Terpercaya",
          ],
          service_route: "/registerAssistant",
          service_img_url: "icon_service_two.png",
          service_button: "Klik Disini",
        },
        {
          service_title: "Bergabung Menjadi Supplier",
          service_desc:
            "Anda supplier bahan bangunan dan masih menggunakan sistem penjualan yang konvensional? Saatnya bergabung di Era Baru Sistem Penjualan secara Digital. ",
          service_feature: [
            "Sistem Penjualan Online (Efisiensi Biaya)",
            "Meminimalisir Kesalahan Manusia",
            "Real Time",
            "Promosi dan Memperluas Pasar",
          ],
          service_route: "/registerSupplier",
          service_img_url: "icon_service_three.png",
          service_button: "Klik Disini",
        },
        {
          service_title: "Bergabung Menjadi Kontraktor dan Designer",
          service_desc:
            "Anda kontraktor dan designer yang membutuhkan partner dalam mengembangkan usaha? Bertemu dengan berbagai peluang tak terbatas dengan Customer Baru. Ayo Bergabung. ",
          service_feature: [
            "Sistem Online",
            "Hemat Waktu dan Biaya",
            "Pembayaran Tepat Waktu",
            "Adanya Kontrak Jasa Yang Terpercaya",
            "Memperluas Pasar Anda",
          ],
          service_route: "/registerContractor",
          service_img_url: "icon_service_four.png",
          service_button: "Klik Disini",
        },
      ],
    };
  },
  methods: {
    scrollMeTo(refName) {
      const top = this.$refs[refName].offsetTop;
      window.scrollTo(0, top);
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_landing_page.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
}

/* .header-2 {
  background-image: url("../../assets/background_wall2.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
} */

.header-2 {
  background-image: url("../../assets/background_five.jpg");
  background-size: cover;
  position: relative;
}

.header-3 {
  background-image: url("../../assets/background_three.jpg");
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
