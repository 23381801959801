import api from "@/services/ApiService";

export default {
  printPenjualanPerPelanggan(payload) {
    return api().get(
      `/report/penjualan_per_pelanggan/` +
        payload.from_date +
        "/" +
        payload.to_date +
        "/" +
        payload.customer_id,
      {
        responseType: "blob"
      }
    );
  },
  printPenjualanPerProduk(payload) {
    return api().get(
      `/report/penjualan_per_produk/` +
        payload.from_date +
        "/" +
        payload.to_date +
        "/" +
        payload.product_id,
      {
        responseType: "blob"
      }
    );
  },
  printDaftarPenjualan(payload) {
    return api().get(
      `/report/get_invoice_list/` + payload.from_date + "/" + payload.to_date,
      {
        responseType: "blob"
      }
    );
  },
  printPengirimanPerPengemudi(payload) {
    return api().get(
      `/mitra/report/product-delivery/` +
        payload.from_date +
        "/" +
        payload.to_date +
        "/" +
        payload.driver_id,
      {
        responseType: "blob"
      }
    );
  },
  printStokOpname(payload) {
    return api().get(
      `/mitra/report/stock-opname/` +
        payload.from_date +
        "/" +
        payload.to_date +
        "/" +
        payload.product_id +
        "/" +
        payload.type,
      {
        responseType: "blob"
      }
    );
  },
  printDebt(payload) {
    return api().get(
      `/report/debt?start_date=` +
        payload.from_date +
        "&end_date=" +
        payload.to_date +
        "&type=" +
        payload.type +
        "&customer_id=" +
        payload.customer_id +
        "&debt_status=" +
        payload.debt_status,
      {
        responseType: "blob"
      }
    );
  },
  printCashInOut(payload) {
    return api().get(
      `/report/cash_in_out?start_date=` +
        payload.from_date +
        "&end_date=" +
        payload.to_date +
        "&operator_id=" +
        payload.operator_id,
      {
        responseType: "blob"
      }
    );
  },
  getCustomer() {
    return api().post("/mitra/get_customer");
  },
  getProduct() {
    return api().post("/mitra/get_product");
  },
  getDriver() {
    return api().get("/mitra/driver");
  }
};
