<template>
  <v-navigation-drawer
    v-model="$store.state.common.triggerSidebar"
    v-if="isLoggedIn"
    color="primary"
    width="300"
    dark
    app
  >
    <v-list>
      <v-list-item two-line>
        <!-- <v-list-item-avatar>
            <img src="https://randomuser.me/api/portraits/men/81.jpg" />
          </v-list-item-avatar> -->

        <div v-if="role === 5">
          <v-list-item-title>{{ getUser.user.store_name }}</v-list-item-title>
        </div>
        <div v-else>
          <v-list-item-content>
            <v-list-item-title>{{ getUser.user.store_name }}</v-list-item-title>
            <v-list-item-subtitle>{{
              getUser.user.store_name
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </div>
      </v-list-item>

      <v-divider />

      <div v-if="role === 4">
        <v-list-item
          v-for="(item, i) in mitraItems"
          :key="i"
          :to="item.route"
          class="py-1"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else-if="role === 5">
        <v-list-item
          v-for="(item, i) in supplierItems"
          :key="i"
          :to="item.route"
          class="py-1"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
            <!-- <div v-if="getNotification[i].notification !== 0">
                <v-badge
                  color="success"
                  :content="
                    getNotification !== null ? getNotification[i].notification : 0
                  "
                />
              </div> -->
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else>
        <v-list-item
          v-for="(item, i) in adminItems"
          :key="i"
          :to="item.route"
          class="py-1"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
            <!-- <div v-if="getNotification[i].notification !== 0">
                <v-badge
                  color="success"
                  :content="
                    getNotification !== null ? getNotification[i].notification : 0
                  "
                />
              </div> -->
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      mitraItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "dashboardMitra"
        },
        { title: "Akunting", icon: "mdi-chart-pie", route: "accounting" },
        { title: "Kelola Admin", icon: "mdi-account", route: "manageAdmin" },
        { title: "Keuangan", icon: "mdi-chart-line", route: "finance" },
        { title: "Laporan", icon: "mdi-chart-gantt", route: "report" },
        { title: "Penjualan", icon: "mdi-currency-usd", route: "sales" },
        { title: "Stok", icon: "mdi-chart-bar", route: "stock" },
        {
          title: "Tagihan Pembelian",
          icon: "mdi-chart-bubble",
          route: "purchaseBill"
        },
        {
          title: "Upload Produk Toko",
          icon: "mdi-upload",
          route: "uploadStoreProduct"
        }
      ],
      supplierItems: [
        {
          title: "Daftar Produk",
          icon: "mdi-apps",
          route: "productSupplier"
        },
        {
          title: "Daftar Pesanan Barang",
          icon: "mdi-chart-gantt",
          route: "purchaseOrderSupplier"
        },
        {
          title: "Daftar Faktur",
          icon: "mdi-chart-bubble",
          route: "invoiceSupplier"
        },
        {
          title: "Daftar Komplain",
          icon: "mdi-note",
          route: "complainSupplier"
        },
        {
          title: "Surat Jalan",
          icon: "mdi-truck",
          route: "airwayBillSupplier"
        }
      ],
      adminItems: [
        {
          title: "Kelola Supplier",
          icon: "mdi-bookmark-multiple",
          route: "manageSupplier"
        },
        {
          title: "Pembayaran",
          icon: "mdi-checkbox-multiple-marked",
          route: "paymentAdmin"
        },
        {
          title: "Registrasi Assisten",
          icon: "mdi-format-paint",
          route: "assistantRegistration"
        },
        {
          title: "Registrasi Kontraktor",
          icon: "mdi-flag",
          route: "contractorRegistration"
        },
        {
          title: "Registrasi Mitra",
          icon: "mdi-account",
          route: "mitraRegistration"
        },
        {
          title: "Registrasi Supplier",
          icon: "mdi-inbox",
          route: "supplierRegistration"
        },
        {
          title: "Upload Master Produk",
          icon: "mdi-upload",
          route: "uploadProductMaster"
        }
      ]
    };
  },

  computed: {
    ...mapGetters(["getUser"]),
    ...mapGetters(["isLoggedIn"]),
    ...mapGetters(["role"]),
    ...mapGetters(["getNotification"])
  }
};
</script>
