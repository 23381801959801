import api from "@/services/ApiService";

export default {
  getAll() {
    return api().post("/dashboard/latest_transaction");
  },
  getDetail(id) {
    // return api().post(`/transaction/detail/${id}`);
    return api().post(`/transaction/detail`, { id });
  },
  getBySearch(params) {
    return api().post(`/dashboard/latest_transaction`, params);
  },
  printReceipt(noTransaction) {
    return api().get(`/report/receipt/` + noTransaction, {
      responseType: "blob"
    });
  }
};
