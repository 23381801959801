<template>
  <div id="RegisterContractor">
    <div class="header-1 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-16">
          <div class="secondary--text text-h5 text-md-h2 pa-5">
            Ayo Renovasi Rumahmu Bersama
          </div>
          <div class="secondary--text text-h5 text-md-h2 pa-5">
            Asisten Renovasi Lajual
          </div>
        </div>
      </v-container>
    </div>

    <div class="section-one">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex">
              <v-img
                alt="Contact Us"
                src="@/assets/icon_designer.jpg"
                class="mx-auto"
                max-height="500"
                max-width="500"
              />
            </v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title>
                  <div class="text-center text-lg-left text-h6 text-lg-h5 mt-5">
                    Praktis Renovasi Rumah Dengan Kontraktor Profesional
                  </div>
                </v-card-title>
                <v-card-text>
                  <p class="text-center text-lg-left">
                    Renovasi rumah dengan hasil sesuai ekspektasi. Lakukan
                    perbaikan, perubahan atau penambahan ruangan bersama
                    kontraktor SobatBangun yang berpengalaman dan terpercaya
                  </p>
                  <p class="text-center text-lg-left">
                    Asisten Renovasi Rumahmu Membantu Anda membangun rumah
                    idaman
                  </p>
                  <v-row class="justify-center text-center mb-5">
                    <v-btn outlined color="info" class="ma-2">Nyaman</v-btn>
                    <v-btn outlined color="info" class="ma-2">Mudah</v-btn>
                    <v-btn outlined color="info" class="ma-2">Terpercaya</v-btn>
                  </v-row>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn
                      large
                      color="info"
                      @click="scrollMeTo('registration')"
                      >Daftar Sekarang</v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-two secondary">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Keuntungan Renovasi Bersama Lajual
          </div>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="500" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Hasil Kerja Profesional
                </v-card-subtitle>
                <v-card-text class="text-center text-lg-left"
                  >Seluruh tim renovasi merupakan profesional yang ahli
                  dibidangnya dan berpengalaman.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="500" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Kontraktor Terpercaya
                </v-card-subtitle>
                <v-card-text class="text-center text-lg-left"
                  >Seluruh kontraktor yang bergabung dengan lajual telah melalui
                  proses verifikasi yang ketat dan berstandar.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="500" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Asisten Pengawas dan Pendamping
                </v-card-subtitle>
                <v-card-text class="text-center text-lg-left"
                  >Asisten renovasi Lajual selalu memberikan pendampingan
                  terkait kebutuhan pengguna jasa dan dapat memberikan jasa
                  pengawasan.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="500" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Rencana Anggaran dan Harga Terbaik
                </v-card-subtitle>
                <v-card-text class="text-center text-lg-left"
                  >Memberikan kemudahan bagi pengguna untuk memilih penyedia
                  material bangunan terdekat dengan harga yang optimal.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="500" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Bergaransi
                </v-card-subtitle>
                <v-card-text class="text-center text-lg-left"
                  >Jaminal garansi terhadap kepuasan pelanggan terhadap hasil
                  pembangunan.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="500  " width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_services.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Aman dan Terpercaya
                </v-card-subtitle>
                <v-card-text class="text-center text-lg-left"
                  >Asisten renovasi lajual berada disisi anda dengan memberikan
                  bantuan perlindungan penuh secara hukum pada jasa yang
                  ditawarkan oleh kontraktor.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-three info">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div
            class="secondary--text text-h5 text-lg-h4 text-center mt-5 mb-10"
          >
            Contoh Rumah dan Pelanggan
          </div>
          <v-row>
            <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
              <v-img
                :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                class="grey lighten-2"
              >
              </v-img>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-four">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Tahapan Cara Kerja
          </div>
          <v-row>
            <v-col>
              <v-sheet class="secondary--text pa-7" elevation="5">
                <v-row>
                  <v-col class="col-2">
                    <div class="py-5 secondary--text primary text-center">
                      1
                    </div>
                  </v-col>
                  <v-col class="col-10 primary--text d-flex align-center">
                    Daftar renovasi via web / whatsapp
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet class="secondary--text pa-7" elevation="5">
                <v-row>
                  <v-col class="col-2">
                    <div class="py-5 secondary--text primary text-center">
                      2
                    </div>
                  </v-col>
                  <v-col class="col-10 primary--text d-flex align-center">
                    Tim Asisten Renovasi Lajual akan menghubungi via whatsapp
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-sheet class="secondary--text pa-7" elevation="5">
                <v-row>
                  <v-col class="col-2">
                    <div class="py-5 secondary--text primary text-center">
                      3
                    </div>
                  </v-col>
                  <v-col class="col-10 primary--text d-flex align-center">
                    Konsultasi dan Negosiasi
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col>
              <v-sheet class="secondary--text pa-7" elevation="5">
                <v-row>
                  <v-col class="col-2">
                    <div class="py-5 secondary--text primary text-center">
                      4
                    </div>
                  </v-col>
                  <v-col class="col-10 primary--text d-flex align-center">
                    Penggerjaan Renovasi
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-five">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="justify-center">
            <div class="text-center text-h5 text-lg-h4 mx-5 mb-16">
              Mari Membangun Era Baru Bersama
            </div>
          </v-row>
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex"
              ><v-img
                alt="Contact Us"
                src="@/assets/image_technology.jpg"
                class="mx-auto"
                max-height="500"
                max-width="500"
                transition="scale-transition"
            /></v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title class="justify-center">
                  <div class="text-h6 text-center text-md-left text-lg-h5 mt-5">
                    Bangun bisnis anda yang tersistematis dengan teknologi
                  </div>
                </v-card-title>
                <v-card-text>
                  <p
                    class="black--text font-weight-bold text-center text-md-left"
                  >
                    Jadilah yang pertama bergabung
                  </p>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn
                      large
                      color="info"
                      @click="scrollMeTo('registration')"
                      >Daftar Sekarang</v-btn
                    >
                  </v-row>

                  <v-row
                    class="justify-center justify-lg-start mt-10 mb-5 mx-0"
                  >
                    <v-sheet class="secondary--text py-2 px-5" color="success">
                      <div>
                        <v-icon left dark>
                          mdi-whatsapp
                        </v-icon>
                        +62 812 126 126 86
                      </div>
                    </v-sheet>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-six" ref="registration">
      <v-container class="ext" py-16>
        <div class="py-16 mx-5">
          <v-row class="justify-center">
            <div class="text-center text-h5 text-lg-h4 mb-16">
              Pendaftaran Renovasi Rumah
            </div>
          </v-row>
          <div class="text-h6">
            Silahkan isi informasi kamu dibawah ini
          </div>
          <v-form ref="form" v-model="valid" autocomplete="off">
            <v-row>
              <v-col class="col-12 col-md-7">
                <v-text-field
                  v-model="assistant.fullName"
                  :counter="50"
                  :rules="fullNameRules"
                  label="Nama Lengkap*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="assistant.occupation"
                  :rules="occupationRules"
                  label="Pekerjaan*"
                  clearable
                  required
                ></v-text-field>
                <v-menu
                  v-model="menuBirthDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="assistant.birthDate"
                      label="Tanggal Lahir*"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      :rules="birthDateRules"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="assistant.birthDate"
                    @input="menuBirthDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  v-model="assistant.email"
                  :rules="emailRules"
                  label="Email*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="assistant.whatsappNumber"
                  :counter="12"
                  :rules="whatsappNumberRules"
                  label="Nomor Whatsapp*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="assistant.address"
                  :counter="150"
                  :rules="addressRules"
                  label="Alamat Lokasi Renovasi*"
                  clearable
                  required
                ></v-text-field>
                <v-text-field
                  v-model="assistant.postalCode"
                  :counter="5"
                  :rules="postalCodeRules"
                  label="Kode Pos*"
                  v-mask="['#####']"
                  clearable
                  required
                ></v-text-field>
                <v-textarea
                  v-model="assistant.occupationDetail"
                  label="Jelaskan Jasa Renovasi Yang Anda Butuhkan (Dekorasi / Perbaikan / Penambahan Ruangan)*"
                  :counter="255"
                  :rules="occupationDetailRules"
                  class="mt-5"
                  outlined
                  no-resize
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="col-7">
              <v-btn
                :disabled="loading"
                :loading="loading"
                color="success"
                @click="onSubmitClick"
                >Submit</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
  data() {
    return {
      menuBirthDate: false,
      assistant: {},
      valid: true,
      loading: false,
      mitraImageUrls: [
        "https://jasadesainlogo.id/wp-content/uploads/2020/01/putra-garuda-966x675.png",
        "https://sribu-sg.s3.amazonaws.com/assets/media/contest_detail/2018/1/kontes-logo-untuk-toko-bangunan-5a538a149d68b1631e925e35/normal_223f5948f1.jpg",
        "https://cdn.sribu.com/assets/media/contest_detail/2015/11/toko-bungunan-5653ef78ca6bcb4fb6000003/ea777e0db0.jpg",
        "https://assets.karirpad.com/uploads/employer/logo/2019-07/87c3c379ac74b255cd6dd7049634bb79.png",
        "https://cdn.sribu.com/assets/media/contest_detail/2013/6/logo-untuk-toko-subur-makmur-bangunan-51adae0ea4b1b03fe700013e/4a9ae288c2.png",
        "https://dexpobangunan.files.wordpress.com/2015/06/20688_1388769734775162_1395825442704902278_n.png",
        "https://1.bp.blogspot.com/-3Xi9UOun6LY/XqGWVHB9GJI/AAAAAAADmUo/r5YdjjUglb84pKDQMNJMSUm5gyNGDMz_gCLcBGAsYHQ/s1600/murah%2Bjaya.PNG"
      ],
      fullNameRules: [
        v => !!v || "Nama Lengkap wajib diisi",
        v =>
          (v && v.length <= 50) || "Nama Lengkap wajib kurang dari 50 karakter"
      ],
      occupationRules: [v => !!v || "Pekerjaan wajib diisi"],
      birthDateRules: [v => !!v || "Tanggal Lahir wajib diisi"],
      emailRules: [
        v => !!v || "Email wajib diisi",
        v => /.+@.+\..+/.test(v) || "Email tidak valid"
      ],
      whatsappNumberRules: [
        v => !!v || "Nomor Whatsapp wajib diisi",
        v =>
          /^(^\+62\s?|^0)(\d{3,4}-?){2}\d{3,4}$/.test(v) ||
          "Nomor Whatsapp tidak valid"
      ],
      addressRules: [
        v => !!v || "Alamat wajib diisi",
        v => (v && v.length <= 150) || "Alamat wajib kurang dari 150 karakter"
      ],
      postalCodeRules: [
        v => !!v || "Kode Pos wajib diisi",
        v => /[0-9]/.test(v) || "Kode Pos tidak valid",
        v => (v && v.length === 5) || "Kode Pos wajib 5 karakter"
      ],
      occupationDetailRules: [
        v => !!v || "Penjelasan wajib diisi",
        v =>
          (v && v.length <= 255) || "Penjelasan wajib kurang dari 255 karakter"
      ]
    };
  },
  methods: {
    scrollMeTo(refName) {
      const top = this.$refs[refName].offsetTop;
      window.scrollTo(0, top);
    },
    onSubmitClick() {
      this.loading = true;
      this.$refs.form.validate();

      const payload = {
        responsible_name: this.assistant.fullName,
        current_occupation: this.assistant.occupation,
        responsible_birth_date: this.assistant.birthDate,
        email: this.assistant.email,
        phone_number: this.assistant.whatsappNumber,
        working_address: this.assistant.address,
        working_postal_code: this.assistant.postalCode,
        occupation_detail: this.assistant.occupationDetail
      };

      CommonService.submitAssistant(payload)
        .then(response => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.$refs.form.reset();
            this.$tostini({
              message:
                "Pendaftaraan Asisten Renovasi Berhasil, Data Anda Sedang Diproses oleh Admin Kami, Terima Kasih.",
              type: "success",
              timeout: 2000
            });
            this.$router.push("/");
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_eleven.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
