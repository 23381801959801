import axios from "axios";
// import NProgress from "nprogress";
// import "../../node_modules/nprogress/nprogress.css";

export default () => {
  const api = axios.create({
    baseURL: process.env.VUE_APP_PROD,
    withCredentials: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  // before a request is made start the nprogress
  api.interceptors.request.use(
    (config) => {
      // NProgress.start();

      return config;
    },
    function(error) {
      // NProgress.done();
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // before a response is returned stop nprogress
  api.interceptors.response.use(
    (response) => {
      // NProgress.done();

      return response;
    },
    function(error) {
      // NProgress.done();
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  // api.interceptors.response.use(null, error => {
  //   NProgress.done();
  //   // let path = '/error';
  //   // switch (error.response.status) {
  //   //   case 401: path = '/login'; break;
  //   //   case 404: path = '/404'; break;
  //   // }
  //   // router.push(path);
  //   // alert(error);
  //   return Promise.reject(error);
  // });

  return api;
};
