import api from "@/services/ApiService";
import {
  ERROR_SOMETHING_WRONG,
  SUCCESS_UPLOAD,
  SUCCESS_CHANGE_PRODUCT,
  ERROR_DATA_NOT_FOUND
} from "@/utils/message";

const product = {
  namespaced: true,
  state: {
    listProducts: [],
    listCategories: []
  },
  getters: {
    getSupplierProducts(state) {
      return state.listProducts;
    },
    getSupplierCategories(state) {
      return state.listCategories;
    }
  },
  actions: {
    getSupplierProducts({ commit }) {
      commit("SET_LOADING", true, { root: true });

      api()
        .get("/supplier/product")
        .then(response => {
          const payload_product = response.data.products;
          const payload_category = response.data.categories;
          commit("GET_SUPPLIER_PRODUCTS", payload_product);
          commit("GET_SUPPLIER_CATEGORIES", payload_category);
        })
        .catch(() => {
          commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
        })
        .finally(() => commit("SET_LOADING", false, { root: true }));
    },
    getProductBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .get(
            `/supplier/product?product_name=${params.product_name}&category_id=${params.category_id}`
          )
          .then(response => {
            const payload_product = response.data.products;

            if (payload_product.length === 0) {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("GET_SUPPLIER_PRODUCTS", payload_product);
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => commit("SET_LOADING", false, { root: true }));
      });
    },
    uploadStoreProduct({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post(`/mitra/store-product/store-product-update`, params, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            if (response.data.status === 0) {
              let messageError = `${response.data.data[0].error} Pada Baris ${response.data.data[0].row}`;
              commit("SET_MESSAGE", messageError, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_UPLOAD, { root: true });
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    updateProductData({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post(`/supplier/product/edit`, params)
          .then(() => {
            commit("SET_MESSAGE", SUCCESS_CHANGE_PRODUCT, { root: true });
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_SUPPLIER_PRODUCTS: (state, payload) => {
      state.listProducts = payload;
    },
    GET_SUPPLIER_CATEGORIES: (state, payload) => {
      state.listCategories = payload;
    }
  }
};

export default product;
