import api from "@/services/ApiService";
import {
  ERROR_SOMETHING_WRONG,
  ERROR_DATA_NOT_FOUND,
  SUCCESS_ADD_AIRWAY_BILL
} from "@/utils/message";

const airwayBill = {
  namespaced: true,
  state: {
    airwayBills: [],
    airwayBillDetail: {},
    airwayBillPurchaseOrders: {},
    airwayBillPurchaseOrderDetail: {}
  },
  getters: {
    getSupplierAirwayBills(state) {
      return state.airwayBills;
    },
    getSupplierAirwayBillDetail(state) {
      return state.airwayBillDetail;
    },
    getSupplierAirwayBillPurchaseOrders(state) {
      return state.airwayBillPurchaseOrders;
    },
    getSupplierAirwayBillPurchaseOrderDetail(state) {
      return state.airwayBillPurchaseOrderDetail;
    }
  },
  actions: {
    getSupplierAirwayBills({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post("/supplier/airwaybill")
          .then(response => {
            const payload = response.data?.airways;
            commit("GET_AIRWAY_BILLS", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => commit("SET_LOADING", false, { root: true }));
      });
    },
    getAirwayBillDetailById({ commit }, id) {
      return new Promise((resolve, reject) => {
        api()
          .post("/supplier/airwaybill/detail", id)
          .then(response => {
            const payload = response.data?.airway;
            commit("GET_AIRWAY_BILL_DETAIL", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          });
      });
    },
    getAirwayBillBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post(`/supplier/airwaybill`, params)
          .then(response => {
            const payload_airway_bills = response.data?.airways;

            if (response.data.status === 0) {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("GET_AIRWAY_BILLS", payload_airway_bills);
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printAirwayBill({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .get(`/supplier/airwaybill/print_airwaybill/` + params.id, {
            responseType: "blob"
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", params.airwayBillNo + ".pdf");
            document.body.appendChild(link);
            link.click();
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    addAirwayBill({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post(`/supplier/airwaybill/add`, params)
          .then(() => {
            commit("SET_MESSAGE", SUCCESS_ADD_AIRWAY_BILL, { root: true });
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getPurchaseOrders({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post("/supplier/airwaybill/po")
          .then(response => {
            const payload = response.data?.po;
            commit("GET_AIRWAY_BILL_PURCHASE_ORDERS", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => commit("SET_LOADING", false, { root: true }));
      });
    },
    getPurchaseOrderDetail({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post(`/supplier/purchase_order/detail`, id)
          .then(response => {
            const payload = response.data?.purchase_order;
            commit("GET_AIRWAY_BILL_PURCHASE_ORDER_DETAIL", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => commit("SET_LOADING", false, { root: true }));
      });
    }
  },
  mutations: {
    GET_AIRWAY_BILLS: (state, payload) => {
      state.airwayBills = payload;
    },
    GET_AIRWAY_BILL_DETAIL: (state, payload) => {
      state.airwayBillDetail = payload;
    },
    GET_AIRWAY_BILL_PURCHASE_ORDERS: (state, payload) => {
      state.airwayBillPurchaseOrders = payload;
    },
    GET_AIRWAY_BILL_PURCHASE_ORDER_DETAIL: (state, payload) => {
      state.airwayBillPurchaseOrderDetail = payload;
    }
  }
};

export default airwayBill;
