import FinanceService from "@/services/FinanceService";
import { ERROR_SOMETHING_WRONG } from "@/utils/message";

const product = {
  namespaced: true,
  state: {
    listFinance: [],
    financeGraphic: {}
  },
  getters: {
    getFinances(state) {
      return state.listFinance;
    },
    getGraphic(state) {
      return state.financeGraphic;
    }
  },
  actions: {
    getAll({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        FinanceService.getAll()
          .then(response => {
            const payload_finance = response.data?.data;
            commit("GET_FINANCE", payload_finance);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        FinanceService.getBySearch(params)
          .then(response => {
            if (response.data?.status === "0") {
              commit("SET_MESSAGE", response.data?.message, {
                root: true
              });
              reject();
            } else {
              commit("GET_FINANCE", response.data?.data);
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    getFinanceGraphic({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        FinanceService.getGraphic()
          .then(response => {
            const payload_finance_graphic = response.data?.data;

            const graphicData = {
              cash: [
                {
                  nominal: "Pengeluaran",
                  value: payload_finance_graphic.kas_pengeluaran
                },
                {
                  nominal: "Pemasukan",
                  value: payload_finance_graphic.kas_pemasukan
                }
              ],
              bank: [
                {
                  nominal: "Pengeluaran",
                  value: payload_finance_graphic.bank_pengeluaran
                },
                {
                  nominal: "Pemasukan",
                  value: payload_finance_graphic.bank_pemasukan
                }
              ]
            };

            commit("GET_FINANCE_GRAPHIC", graphicData);
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_FINANCE: (state, payload) => {
      state.listFinance = payload;
    },
    GET_FINANCE_GRAPHIC: (state, payload) => {
      state.financeGraphic = payload;
    }
  }
};

export default product;
