<template>
  <div>
    <v-app-bar app color="secondary">
      <v-app-bar-nav-icon v-if="isLoggedIn" @click.stop="triggerSidebar" />

      <router-link to="/" class="text-decoration-none ml-5">
        <v-row class="align-center">
          <v-img width="160" src="@/assets/lajual_logo_text.png"></v-img>
        </v-row>
      </router-link>

      <v-spacer />

      <v-menu offset-y v-if="!isLoggedIn">
        <template v-slot:activator="{ on }">
          <v-btn
            class="text-button text-sm-body-2 d-inline-flex d-md-none"
            color="info"
            small
            fab
            v-on="on"
          >
            <v-icon dark>
              mdi-menu
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/registerMitra" active-class="no-active">
            <v-list-item-title>Daftar Menjadi Mitra</v-list-item-title>
          </v-list-item>
          <v-list-item to="/registerSupplier" active-class="no-active">
            <v-list-item-title>Daftar Menjadi Supplier</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <!-- <v-list-item to="/profile" active-class="no-active">
            <v-list-item-group>
              <v-list-item-title>Tentang Perusahaan</v-list-item-title>
            </v-list-item-group>
          </v-list-item> -->
          <!-- <v-list-item to="/career" active-class="no-active">
            <v-list-item-group>
              <v-list-item-title>Karir</v-list-item-title>
            </v-list-item-group>
          </v-list-item> -->
          <v-list-item to="/contactUs" active-class="no-active">
            <v-list-item-group>
              <v-list-item-title>Hubungi Kami</v-list-item-title>
            </v-list-item-group>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-btn
              class="mt-2"
              color="info"
              block
              @click.stop="showLoginModal = !showLoginModal"
            >
              Masuk
              <v-icon>mdi-login</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="!isLoggedIn"
        to="/"
        class="mr-3 my-1 text-button text-sm-body-2 d-none d-md-inline-flex"
        color="primary"
        text
      >
        Beranda
      </v-btn>
      <v-menu open-on-hover offset-y v-if="!isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-3 my-1 text-button text-sm-body-2 d-none d-md-inline-flex"
            color="primary"
            text
            v-bind="attrs"
            v-on="on"
          >
            Layanan Kami
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in services"
            :key="i"
            :to="item.route"
            active-class="no-active"
            link
          >
            <v-list-item-group>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-group>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- <v-menu open-on-hover offset-y v-if="!isLoggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-3 my-1 text-button text-sm-body-2 d-none d-md-inline-flex"
            color="primary"
            text
            v-bind="attrs"
            v-on="on"
          >
            Tentang
          </v-btn>
        </template>
        <v-list>
          <v-list-item to="/profile" link>
            <v-list-item-group>
              <v-list-item-title>Perusahaan</v-list-item-title>
            </v-list-item-group>
          </v-list-item>
          <v-list-item to="/career" link>
            <v-list-item-group>
              <v-list-item-title>Karir</v-list-item-title>
            </v-list-item-group>
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-btn
        v-if="!isLoggedIn"
        to="/contactUs"
        class="mr-3 my-1 text-button text-sm-body-2 d-none d-md-inline-flex"
        color="primary"
        text
      >
        Hubungi Kami
      </v-btn>
      <v-btn
        v-if="!isLoggedIn"
        class="secondary--text my-1 text-button text-sm-body-2 d-none d-md-inline-flex"
        color="#1c6e7d"
        @click.stop="showLoginModal = !showLoginModal"
      >
        Masuk
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <v-btn v-if="isLoggedIn" color="info" @click="logout">
        Keluar
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <!-- <select v-model="$i18n.locale">
        <option value="id">ID</option>
        <option value="en">EN</option>
      </select>

      <div v-if="!isLoggedIn" class="ml-10 d-none d-md-inline-flex">
        <v-btn
          fab
          text
          small
          @click="$i18n.locale = 'id'"
          :class="{ active: $i18n.locale === 'id' }"
          >ID</v-btn
        >
        <v-btn
          fab
          text
          small
          @click="$i18n.locale = 'en'"
          :class="{ active: $i18n.locale === 'en' }"
          >EN</v-btn
        >
      </div> -->
    </v-app-bar>

    <login-modal v-model="showLoginModal" />
  </div>
</template>

<script>
import LoginModal from "@/components/Modal/LoginModal";
import CommonService from "@/services/CommonService";
import store from "@/store/index.js";

export default {
  components: {
    LoginModal,
  },

  data() {
    return {
      fab: false,
      showLoginModal: false,
      sidebar: true,
      services: [
        { title: "Daftar Menjadi Mitra", route: "/registerMitra" },
        // { title: "Asisten Renovasi Rumahmu", route: "/registerAssistant" },
        { title: "Daftar Menjadi Supplier", route: "/registerSupplier" },
        // {
        //   title: "Bergabung Menjadi Kontraktor dan Designer",
        //   route: "/registerContractor",
        // },
      ],
    };
  },

  computed: {
    isLoggedIn() {
      return store.getters.isLoggedIn;
    },
  },

  methods: {
    triggerSidebar() {
      this.sidebar = !this.sidebar;
      this.$store.dispatch("common/setTriggerSidebar", this.sidebar);
    },

    async logout() {
      if (
        await this.$root.$confirmModal(
          "Logout",
          "Apakah anda yakin ingin keluar ?",
          { color: "primary" }
        )
      ) {
        this.$root.$loaderModal.start(
          "Logout...",
          {},
          CommonService.logout()
            .then(() => {
              this.$store.dispatch("removeSession").then(() => {
                this.$router.push("/");
              });
            })
            .catch(() => {
              this.$tostini({
                message: "Gagal Logout",
                type: "error",
              });
            })
            .finally(() => this.$root.$loaderModal.hide()),
          true
        );
      }
    },
  },
};
</script>
