<template>
  <div id="Career">
    <div class="header-1 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-16">
          <div class="secondary--text text-h5 text-md-h2 pa-5">
            Lets Change
          </div>
          <div class="secondary--text text-h4 text-md-h1 pa-5">
            The World Together
          </div>
        </div>
      </v-container>
    </div>

    <div class="section-one">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="black--text text-h5 text-lg-h4 text-center mt-5 mb-10">
            Temukan Posisi yang Anda Cari Disini
          </div>
          <v-row class="mt-10">
            <v-col
              v-for="(data, i) in ourJobs"
              :key="i"
              class="d-flex justify-center"
            >
              <v-card class="mt-10 secondary" width="550">
                <div class="pa-5">
                  <v-card-title>
                    <div class="primary--text text-h5">
                      {{ data.job_title }}
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <!-- <p class="black--text mt-3 mb-12">
                      {{ data.job_desc }}
                    </p> -->

                    <p class="font-weight-bold">Kualifikasi:</p>
                    <div v-for="(item, i) in data.job_benefit" :key="i">
                      <v-chip class="my-2" outlined>
                        <v-icon small left>
                          mdi-check
                        </v-icon>
                        {{ item }}
                      </v-chip>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-btn x-large color="info" class="my-10">Lihat Lebih Banyak</v-btn>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-two info">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div
            class="secondary--text text-h6 text-lg-h5 text-center mt-5 mb-10"
          >
            Beberapa Kumpulan Foto dari Tim Kami yang terlihat bersemangat untuk
            membantu tiap kalangan masyakarat
          </div>
          <v-row>
            <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
              <v-img
                :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
                :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
                class="grey lighten-2"
              >
              </v-img>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-three">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row>
            <v-col class="col-6 d-none d-md-inline-flex"
              ><v-img
                alt="Contact Us"
                src="@/assets/image_desk.jpg"
                class="mx-auto"
                max-height="450"
                max-width="450"
            /></v-col>
            <v-col class="col-12 col-md-6">
              <v-card class="mx-auto px-5">
                <v-card-title class="justify-center justify-md-start">
                  <div class="text-center text-h6 text-lg-h5 mt-5">
                    Tertarik Untuk Bergabung?
                  </div>
                </v-card-title>
                <v-card-text>
                  <p class="black--text text-center text-md-left">
                    Upload CV dan Motivation Letter anda disini!
                  </p>
                  <v-row class="justify-center justify-md-start my-10 mx-0">
                    <v-btn x-large color="info">Bergabung Sekarang</v-btn>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataHelps: [
        {
          help_title: "Pengguna",
          help_img_url: "icon_user.png",
          help_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          help_route: "/contactUs"
        },
        {
          help_title: "Perusahaan",
          help_img_url: "icon_company.png",
          help_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          help_route: "/contactUs"
        },
        {
          help_title: "Kampus",
          help_img_url: "icon_university.png",
          help_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          help_route: "/contactUs"
        },
        {
          help_title: "Media",
          help_img_url: "icon_media.png",
          help_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          help_route: "/contactUs"
        }
      ],
      ourJobs: [
        {
          job_title: "Content Writer",
          job_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
          job_benefit: [
            "Memiliki Kemampuan Menulis",
            "Kemampuan Komunikasi Dengan Baik",
            "Dapat Bekerja Dalam Tekanan dan Deadline"
          ]
        },
        {
          job_title: "Finance Specialist",
          job_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
          job_benefit: [
            "Usia Maksimal 30 Tahun",
            "Pendidikan Minimal S-1",
            "Dapat Mengoprasikan MS Office"
          ]
        },
        {
          job_title: "Backend Software Engineer",
          job_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
          job_benefit: [
            "Memiliki Kemampuan PHP, Laravel",
            "Pendidikan Minimal S-1",
            "Pengetahuan Mengetahui Database Merupakan Nilai Tambah"
          ]
        },
        {
          job_title: "Frontend Software Engineer",
          job_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
          job_benefit: [
            "Memiliki Kemampuan Vue Framework, HTML, CSS, JS",
            "Pendidikan Minimal S-1"
          ]
        },
        {
          job_title: "IT Support",
          job_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
          job_benefit: [
            "Minimal Pengalaman 2 Tahun",
            "Pendidikan Minimal S-1",
            "Dapat Mengoprasikan MS Office"
          ]
        },
        {
          job_title: "Quality Assurance",
          job_desc:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. ",
          job_benefit: [
            "Minimal Pengalaman 2 Tahun",
            "Pendidikan Minimal S-1",
            "Dapat menggunakan Katalon Automation Testing"
          ]
        }
      ]
    };
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_four.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
