<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card class="secondary">
      <v-card-title class="justify-center headline mb-5">Masuk</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail*"
            placeholder="Masukkan Email Anda"
            solo
            autofocus
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRules"
            :type="showEye ? 'text' : 'password'"
            label="Password*"
            placeholder="Masukkan Password Anda"
            solo
            required
            @click:append="showEye = !showEye"
            v-on:keyup.enter="loginUser"
          ></v-text-field>
          <v-btn class="my-5" block color="primary" @click="loginUser"
            >Masuk</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },

  data: () => ({
    showEye: false,
    valid: true,
    email: "",
    emailRules: [
      v => !!v || "Email wajib diisi",
      v => /.+@.+\..+/.test(v) || "Email harus valid"
    ],
    password: "",
    passwordRules: [v => !!v || "Password wajib diisi"]
  }),

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    loginUser() {
      this.$refs.form.validate();
      this.$root.$loaderModal.start("Loading...");

      const data = {
        email: this.email,
        password: this.password
      };

      this.$store
        .dispatch("login", data)
        .then(response => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.dialog = false;
            this.$refs.form.reset();
          }
        })
        .catch(() => {
          this.dialog = false;
          this.$tostini({
            message: "Gagal Login",
            type: "error"
          });
        })
        .finally(() => this.$root.$loaderModal.hide());
    }
  }
};
</script>
