<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card class="secondary">
      <v-card-title class="justify-center headline mb-5"
        >Ubah Password</v-card-title
      >
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field
            v-model="oldPassword"
            :append-icon="oldPasswordShowEye ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="oldPasswordRules"
            :type="oldPasswordShowEye ? 'text' : 'password'"
            label="Old Password*"
            placeholder="Masukkan Password Lama Anda"
            solo
            required
            @click:append="oldPasswordShowEye = !oldPasswordShowEye"
          ></v-text-field>
          <v-text-field
            v-model="newPassword"
            :append-icon="newPasswordShowEye ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="newPasswordRules"
            :type="newPasswordShowEye ? 'text' : 'password'"
            label="New Password*"
            placeholder="Masukkan Password Baru Anda"
            solo
            required
            @click:append="newPasswordShowEye = !newPasswordShowEye"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            :append-icon="confirmPasswordShowEye ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="confirmPasswordRules"
            :type="confirmPasswordShowEye ? 'text' : 'password'"
            label="Confirm Password*"
            placeholder="Ulangi Password Baru Anda"
            solo
            required
            @click:append="confirmPasswordShowEye = !confirmPasswordShowEye"
          ></v-text-field>
          <v-btn class="my-5" block color="success" @click="changePassword"
            >Ubah Password</v-btn
          >
          <v-btn class="my-5" block color="error" @click="logout">Logout</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
  props: {
    value: Boolean
  },

  data: () => ({
    oldPasswordShowEye: false,
    newPasswordShowEye: false,
    confirmPasswordShowEye: false,
    valid: true,
    oldPassword: "",
    oldPasswordRules: [v => !!v || "Password Lama wajib diisi"],
    newPassword: "",
    newPasswordRules: [
      v => !!v || "Password Baru wajib diisi",
      v =>
        /^(?=.*[A-Za-z])[A-Za-z\d]{8,12}$/.test(v) ||
        "Setidaknya ada minimal 8 karakter, maximal 12 karakter, dan 1 angka"
    ],
    confirmPassword: "",
    confirmPasswordRules: [v => !!v || "Ulangi Password wajib diisi"]
  }),

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },

  methods: {
    changePassword() {
      this.$refs.form.validate();
      this.$root.$loaderModal.start("Loading...");

      if (
        this.oldPassword === "" ||
        this.newPassword === "" ||
        this.confirmPassword === ""
      ) {
        this.$root.$loaderModal.hide();
        return this.$tostini({
          message: "Data Wajib Diisi!",
          type: "error"
        });
      }

      if (this.newPassword !== this.confirmPassword) {
        this.$root.$loaderModal.hide();
        return this.$tostini({
          message: "Password Baru Tidak Sama Dengan Password Confirm",
          type: "error"
        });
      }

      const payload = {
        old_password: this.oldPassword,
        new_password: this.newPassword
      };

      CommonService.changePassword(payload)
        .then(response => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.dialog = false;
            this.$tostini({
              message: "Berhasil Ubah Password",
              type: "success"
            });
            this.oldPassword = "";
            this.newPassword = "";
            this.confirmPassword = "";
            window.location.reload();
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    async logout() {
      this.$root.$loaderModal.start(
        "Logout...",
        {},
        CommonService.logout()
          .then(() => {
            this.$store.dispatch("removeSession").then(() => {
              this.$router.push("/");
            });
            this.dialog = false;
          })
          .catch(() => {
            this.$tostini({
              message: "Gagal Logout",
              type: "error"
            });
          })
          .finally(() => {
            this.$root.$loaderModal.hide();
          }),
        true
      );
    }
  }
};
</script>
