import ReportService from "@/services/ReportService";
import { ERROR_DATA_NOT_FOUND, SUCCESS_DOWNLOAD_DATA } from "@/utils/message";

const report = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    printReportSalesList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printDaftarPenjualan(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportSalesProduct({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printPenjualanPerProduk(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportSalesCustomer({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printPenjualanPerPelanggan(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportDriverDelivery({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printPengirimanPerPengemudi(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportStockOpname({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printStokOpname(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportDebt({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printDebt(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printReportCashInOut({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        ReportService.printCashInOut(payload)
          .then(response => {
            if (response.data.type === "application/json") {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("SET_MESSAGE", SUCCESS_DOWNLOAD_DATA, { root: true });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", payload.file_name);
              document.body.appendChild(link);
              link.click();
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {}
};

export default report;
