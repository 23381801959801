<template>
  <div id="Profile">
    <div class="header-1 secondary">
      <div class="black-background"></div>
      <v-container class="ext" py-16>
        <div class="py-16">
          <div class="secondary--text text-h5 text-md-h2 pa-5">
            Tentang Kami
          </div>
        </div>
      </v-container>
    </div>

    <div class="section-one primary">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row class="d-flex justify-center justify-md-start">
            <v-col class="col-3"
              ><v-img
                alt="Contact Us"
                src="@/assets/lajual_logo.png"
                class="mx-auto"
                max-width="150"
            /></v-col>
            <v-col class="col-12 col-md-9 align-self-center">
              <p
                class="secondary--text text-center text-sm-justify text-subtitle-1"
              >
                Kami adalah tim dengan semangat orang-orang yang berkomitmen
                untuk membantu pelanggan dengan produk kami untuk mengelola
                berbagai macam sistem kebutuhan toko anda seperti memanagemen
                produk, kas pengeluaran dan pemasukan, mengelolah data pengguna,
                membuat data laporan secara digital dan teritegrasi mengguna
                penyimpanan cloud.
              </p>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-two secondary">
      <v-container class="ext" py-16>
        <div class="py-16">
          <v-row>
            <v-col class="col-12">
              <div class="text-h6 text-center text-lg-left mt-5 mb-3">
                Our Business
              </div>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item>
                    Lajual merupakan sebuah aplikasi teknologi penggembangan
                    ekosistem industri material bangunan, yang memberikan nilai
                    tambah signifikan kepada para supplier, mitra dan pengguna
                    jasa renovasi, melalui kemudahan supplier dalam memperluas
                    pemasaran, pemanfaatan teknologi management toko bagi mitra,
                    dan menyediakan kebutuhan dan konsultasi terpercaya untuk
                    menjawab kebutuhan pengguna akan jasa renovasi.
                  </v-list-item>
                </v-list-item-group></v-list
              >
              <div class="text-h6 text-center text-lg-left mt-5 mb-3">
                Our Goal
              </div>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    >Menjadi pilar utama teknologi ekosistem industri material
                    bangunan bangsa Indonesia
                  </v-list-item>
                </v-list-item-group></v-list
              >
              <div class="text-h6 text-center text-lg-left mt-5 mb-3">
                Our Strategy
              </div>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item>Memahami kebutuhan mitra</v-list-item>
                  <v-list-item>Mewujudkan keberhasilan bersama</v-list-item>
                  <v-list-item
                    >Menciptakan kebermanfaatan bagi masyarakat
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <div class="text-h6 text-center text-lg-left mt-5 mb-3">
                Our Value
              </div>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item>Integrity</v-list-item>
                  <v-list-item>Customer Focus</v-list-item>
                  <v-list-item>Excellent</v-list-item>
                  <v-list-item>Team Work</v-list-item>
                  <v-list-item>Inovation</v-list-item>
                </v-list-item-group>
              </v-list>
              <div class="text-h6 text-center text-lg-left mt-5 mb-3">
                Our Charter
              </div>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item>#JanganKetinggalan</v-list-item>
                </v-list-item-group></v-list
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-three secondary">
      <v-container class="ext" py-16>
        <div>
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Tantangan
          </div>
          <p class="text-center pa-5">
            Pengelolahan Toko Bangunan masih menggunakan cara yang konvensional,
            pencatatan keuangan di buku, penggunaaan kalkulator, dan pendataan
            produk secara manual. Di era digital ini masih minim edukasi
            pengelolahan toko bangunan untuk berkembang lebih baik.
          </p>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="350" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_user.png"
                    max-height="100"
                    max-width="100"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Waktu pengiriman yang tidak sesuai jadwal
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="350" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_user.png"
                    max-height="100"
                    max-width="100"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Pematokan harga yang mahal
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="350" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_user.png"
                    max-height="100"
                    max-width="100"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Ketidakpastian ketersediaan barang
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-four disabled">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Solusi
          </div>
          <p class="text-center pa-5">
            Kami mencoba melakukan dorongan untuk terciptanya akses teknologi
            perkembangan toko.
          </p>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="350" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_user.png"
                    max-height="100"
                    max-width="100"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Kemudahan dalam pencarian pengguna
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="350" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_user.png"
                    max-height="100"
                    max-width="100"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Kemudahan dalam pengoprasional
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="350" width="300">
                <v-card-title class="justify-center">
                  <v-img
                    src="@/assets/icon_user.png"
                    max-height="100"
                    max-width="100"
                  />
                </v-card-title>
                <v-card-subtitle class="text-h6 black--text text-center mt-5">
                  Kemudahan dalam mendapatkan barang
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-five secondary">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Lajual Social Impact
          </div>
          <p class="text-center pa-5">
            Kami selalu memberikan dampak yang positif sejak pertama kali
            didirikan. hal ini merupakan cahaya semangat untuk menghasilkan
            dampak yang lebih luas di keesokan hari.
          </p>
          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="200" width="350">
                <v-card-title class="text-h6 primary--text justify-center">
                  0%
                </v-card-title>
                <v-card-subtitle
                  class="font-weight-bold black--text text-center mt-5"
                >
                  Kenaikan Pendapatan
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="200" width="350">
                <v-card-title class="text-h6 primary--text justify-center">
                  0%
                </v-card-title>
                <v-card-subtitle
                  class="font-weight-bold black--text text-center mt-5"
                >
                  Social Return on Investment
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-card class="mb-10 py-5" height="200" width="350">
                <v-card-title class="text-h6 primary--text justify-center">
                  0+
                </v-card-title>
                <v-card-subtitle
                  class="font-weight-bold black--text text-center mt-5"
                >
                  Dampak Sosial dan Ekonomi
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <div class="section-six">
      <v-container class="ext" py-16>
        <div class="py-10">
          <div class="text-h5 text-lg-h4 text-center mt-5 mb-10">
            Kenal Lebih Dekat Dengan Tim Kami
          </div>
          <v-row class="mt-16">
            <v-col
              v-for="(data, i) in dataUsers"
              :key="i"
              class="d-flex justify-center"
            >
              <v-card class="mb-10" height="300" width="250" hover>
                <v-card-title class="justify-center">
                  <v-img
                    alt="data.help_title"
                    src="@/assets/image_person_four.jpg"
                    max-height="150"
                    max-width="150"
                  />
                </v-card-title>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">{{
                      data.user_role
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-card-text>
                  {{ data.user_name }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataUsers: [
        {
          user_name: "Orang 1",
          user_role: "CEO",
          user_img_url: ""
        },
        {
          user_name: "Orang 2",
          user_role: "Finance Specialist",
          user_img_url: ""
        },
        {
          user_name: "Orang 3",
          user_role: "Business Analyst",
          user_img_url: ""
        },
        {
          user_name: "Orang 4",
          user_role: "Android Engineer",
          user_img_url: ""
        },
        {
          user_name: "Orang 5",
          user_role: "Frontend Engineer",
          user_img_url: ""
        },
        {
          user_name: "Orang 6",
          user_role: "Backend Engineer",
          user_img_url: ""
        }
      ]
    };
  },
  method: {
    // getImgUrl(imagePath) {
    //   return require("@/assets/" + imagePath);
    // },
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.header-1 {
  background-image: url("../../assets/background_eight.jpg");
  background-size: cover;
  position: relative;
}

.black-background {
  background-color: #000000;
  opacity: 0.5;
  mix-blend-mode: darken;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
</style>
