import OperatorService from "@/services/OperatorService";
import { ERROR_SOMETHING_WRONG, SUCCESS_CHANGE_STATUS } from "@/utils/message";

const product = {
  namespaced: true,
  state: {},
  getters: {},
  actions: {
    updateOperatorStatus({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        OperatorService.updateStatus(params)
          .then(() => {
            commit("SET_MESSAGE", SUCCESS_CHANGE_STATUS, { root: true });
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {}
};

export default product;
