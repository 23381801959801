import api from "@/services/ApiService";
import { ERROR_SOMETHING_WRONG, ERROR_DATA_NOT_FOUND } from "@/utils/message";

const invoice = {
  namespaced: true,
  state: {
    invoices: [],
    invoiceDetail: {}
  },
  getters: {
    getSupplierInvoices(state) {
      return state.invoices;
    },
    getSupplierInvoiceDetail(state) {
      return state.invoiceDetail;
    }
  },
  actions: {
    getSupplierInvoices({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post("/supplier/invoice")
          .then(response => {
            const payload = response.data?.invoices;
            commit("GET_INVOICES", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => commit("SET_LOADING", false, { root: true }));
      });
    },
    getInvoiceDetailById({ commit }, id) {
      return new Promise((resolve, reject) => {
        api()
          .post("/supplier/invoice/detail", id)
          .then(response => {
            const payload = response.data?.invoice;
            commit("GET_INVOICE_DETAIL", payload);
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          });
      });
    },
    getInvoiceBySearch({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .post(`/supplier/invoice`, params)
          .then(response => {
            const payload_invoice = response.data.invoices;

            if (response.data.status === 0) {
              commit("SET_MESSAGE", ERROR_DATA_NOT_FOUND, { root: true });
              reject();
            } else {
              commit("GET_INVOICES", payload_invoice);
              resolve();
            }
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    },
    printInvoice({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING", true, { root: true });

        api()
          .get(
            `/supplier/invoice/print_invoice/` + params.id + "/" + params.flag,
            {
              responseType: "blob"
            }
          )
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", params.invoiceNo + ".pdf");
            document.body.appendChild(link);
            link.click();
            resolve();
          })
          .catch(() => {
            commit("SET_MESSAGE", ERROR_SOMETHING_WRONG, { root: true });
            reject();
          })
          .finally(() => {
            commit("SET_LOADING", false, { root: true });
          });
      });
    }
  },
  mutations: {
    GET_INVOICES: (state, payload) => {
      state.invoices = payload;
    },
    GET_INVOICE_DETAIL: (state, payload) => {
      state.invoiceDetail = payload;
    }
  }
};

export default invoice;
